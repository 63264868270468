<div [class]="{ 'dropdown-center': true, active: isActive }">
  <div
    class="dropdown-toggle"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    #dropdown
    (click)="dropdownOpen($event)"
  >
    <p><ng-content></ng-content></p>
    <med-icon-filter size="16" />
  </div>
  <div
    [class]="{ 'dropdown-menu': true, double: isDoubleInputFilter }"
    aria-labelledby="dropdownMenuButton"
  >
    <app-input
      *ngIf="key !== 'type'"
      class="filter-input"
      [key]="key"
      [isReset]="reset"
      [placeholder]="filterPlaceholders.placeholder"
      [placeholder2]="filterPlaceholders.placeholder2"
      [doubleInput]="isDoubleInputFilter"
      [onCheck]="inputCheckFunction"
      [errorMessage]="getErrorMessage()"
      [maxSymbols]="maxSymbols"
      (onAction)="onSearchInputChange($event)"
    >
      <app-column-sort
        *ngIf="!isDoubleInputFilter && isColumnSortable(key)"
        singleInput
        [bottomBorder]="true"
        [key]="sortKeys[key]"
        (onAction)="onSortChange($event)"
      />
      <app-column-sort
        *ngIf="isDoubleInputFilter && isColumnSortable(key)"
        doubleInput
        [key]="sortKeys[key]"
        (onAction)="onSortChange($event)"
      />
    </app-input>
    <app-checkbox-list
      *ngIf="!isDoubleInputFilter"
      [isReset]="reset"
      [listToRender]="getListToRender()"
      (checkedListChange)="onCheckedListChange($event)"
    />
  </div>
</div>
