import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class AuthWithForcedLoginGuard implements CanActivate, CanActivateChild {
  constructor(private oauthSvc: OAuthService) {}

  canActivate(): boolean {
    return this._handler();
  }

  canActivateChild(): boolean {
    return this._handler();
  }

  private _handler(): boolean {
    const now = +new Date();
    const isExpired = now > this.oauthSvc.getAccessTokenExpiration();

    if (isExpired) {
      this.oauthSvc.initCodeFlow();
    }

    return !isExpired;
  }
}
