import { Component, HostListener, OnInit } from "@angular/core";
import { DataService } from "@app/shared/data.service";
import { UserRole } from "@app/services/user-roles.service";

@Component({
  selector: "app-multiple-level-list",
  templateUrl: "./multiple-level-list.component.html",
  styleUrls: ["./multiple-level-list.component.scss"],
})
export class MultipleLevelListComponent {
  constructor(private dataService: DataService) {
    dataService.showMyOrganizationValue.subscribe((nextValue) => {
      this.showMyOrganization = nextValue;
      this.openLevel = {
        parent: "",
        child: "",
      };
    });
    dataService.organizationCodeValue.subscribe((nextValue) => {
      this.organizationCode = nextValue;
    });
    dataService.userRoleValue.subscribe((nextValue) => {
      this.userRole = nextValue;
    });
    dataService.allOrganizationsValue.subscribe((nextValue) => {
      this.listData = nextValue.data;
      this.listErrorCode = nextValue.errorCode || 0;
    });
  }

  searchValue: string = "";
  showMyOrganization: boolean = false;
  organizationCode: string = "";
  userRole: string = "";
  openLevel = {
    parent: "",
    child: "",
  };
  listData: { [key: string]: any }[] = [];
  listErrorCode: number = 0;
  isSubGroupEmpty = false;

  scrollIntoView(id: string) {
    setTimeout(() => {
      const el = document.getElementById(id);
      el?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  }

  checkGroup(group: { [key: string]: any }) {
    if (this.showMyOrganization && this.userRole !== "MZU") {
      return (
        group["code"] === this.organizationCode ||
        group["children"].some(
          (item: { [key: string]: any }) =>
            item["code"] === this.organizationCode
        )
      );
    }
    return true;
  }

  getGroupToRender(group: any) {
    const result = group["children"].filter((item: { [key: string]: any }) => {
      if (this.showMyOrganization && this.userRole !== UserRole.MZU) {
        const filterResultByRoles =
          (item["code"] === this.organizationCode &&
            this.userRole === UserRole.ZOZ) ||
          ((item["code"] === this.organizationCode ||
            group["code"] === this.organizationCode) &&
            (this.userRole === UserRole.MOZ || this.userRole === UserRole.DOZ));
        if (this.searchValue) {
          return (
            (item["fullName"]
              .toLowerCase()
              .includes(this.searchValue.toLowerCase()) ||
              item["code"] === this.searchValue) &&
            filterResultByRoles
          );
        }
        return filterResultByRoles;
      }
      if (this.searchValue) {
        return (
          item["fullName"]
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          item["code"] === this.searchValue
        );
      }
      return true;
    });
    this.isSubGroupEmpty = !result.length;
    return result;
  }

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.searchValue = target.value;
  }

  @HostListener("click", ["$event"])
  toggleParent(event: MouseEvent, index: string) {
    event.stopPropagation();
    event.preventDefault();
    this.searchValue = "";
    this.openLevel.parent = this.openLevel.parent === index ? "" : index;
    if (this.openLevel.parent) {
      this.scrollIntoView(this.openLevel.parent);
    }
  }

  @HostListener("click", ["$event"])
  toggleChild(event: MouseEvent, index: string) {
    if ((event.currentTarget as any)?.classList.contains("sub-group-header")) {
      this.openLevel.child = this.openLevel.child === index ? "" : index;
      if (this.openLevel.child) {
        this.scrollIntoView(this.openLevel.child);
      }
    }
  }
}
