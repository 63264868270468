import {Component, EventEmitter, Output, ViewChild,} from '@angular/core';
import {ModalContainerComponent} from '@app/modal/modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ModalBookingEditService} from '../modal-booking-edit/modal-booking-edit.service';

@Component({
  standalone: true,
  selector: 'app-modal-booking-cancel',
  templateUrl: './modal-booking-cancel.component.html',
  styleUrls: ['./modal-booking-cancel.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    LoadingAnimationComponent,
    FormsModule,
  ],
})
export class ModalBookingCancelComponent {
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  @Output() hidden = new EventEmitter<void>();
  @Output() visible = new EventEmitter<void>();
  protected cancelReason: string = '';
  protected inputErrorText: string = '';
  protected loadingStatus: boolean = false;
  protected isOwner: boolean = false;
  private offerId: number = 0;

  constructor(
    private toastService: ToastService,
    private modalBookingEditService: ModalBookingEditService
  ) {
  }

  reset() {
    this.cancelReason = '';
    this.inputErrorText = '';
    this.loadingStatus = false;
    this.offerId = 0;
    this.isOwner = false;
  }

  open({id, isOwner}: { id: number, isOwner: boolean }) {
    this.reset();
    this.visible.emit();
    this.offerId = id;
    this.isOwner = isOwner;
    this.modalContainer.openModal();
  }

  close() {
    this.hidden.emit();
  }

  cancel() {
    if (!this.cancelReason) {
      this.inputErrorText = 'Поле обовʼязкове до заповнення';
      return;
    }
    this.loadingStatus = true;
    this.modalBookingEditService
      .cancelBookingOffer(this.offerId, this.cancelReason)
      .subscribe(
        () => {
          this.loadingStatus = false;
          this.modalContainer.close();
          this.hidden.emit();
          this.toastService.showSuccess('Запис успішно оновлено');
        },
        (error) => {
          this.loadingStatus = false;
          this.modalContainer.close();
          if (error.status >= 500) {
            this.toastService.showError(
              'Сервер не доступний.\nСпробуйте пізніше, або зверніться до адміністратора.'
            );
          } else {
            this.toastService.showError();
          }
        }
      );
  }
}
