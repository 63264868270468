export const tableHeader = [
  { key: "mnnName", label: "МНН" },
  { key: "dosage", label: "Дозування" },
  { key: "dueDate", label: "Придатний до" },
  { key: "tradeName", label: "Торгова назва" },
  { key: "treatmentProgram", label: "Напрям" },
  { key: "series", label: "Серія/Серійний \nномер" },
  { key: "minUnit", label: "Мін. облікова \nодиниця" },
  { key: "redistributionOffer", label: "Запропоновано \nна перерозподіл" },
  { key: "bookedQuantity", label: "Зарезервовано \nна перерозподіл" },
  { key: "institutionName", label: "Назва закладу" },
];
