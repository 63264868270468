import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {env} from '@environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalRedistributionCreateService {
    constructor(private http: HttpClient) {
    }

    creatRedistributionOffer({id, amount, organizations}: {
        id: number;
        amount: number;
        organizations: number[]
    }): Observable<any> {
        return this.http.post<any>(`${env.apiUrl}/accounting/v1/redistribution-offer`, {
            medicineMedicalDeviceId: id,
            offeredAmount: amount,
            organizations,
        });
    }
}
