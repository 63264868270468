<div
  class="modal fade"
  #modal
  id="modal"
>
  <div class="modal-dialog modal-dialog-centered" [style.max-width.px]="width">
    <div class="modal-content" [style.height.px]="height">
      <ng-content></ng-content>
      <button
        type="button"
        class="close-cross-btn"
        (click)="close()"
        aria-label="Close"
      >
        <img
          src="assets/icons/x.svg"
          alt="Close Icon"
          width="16px"
          height="16px"
        />
      </button>
    </div>
  </div>
</div>
