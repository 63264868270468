import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { CheckboxModule } from "@app/checkbox/checkbox.module";
import {
  MedIconSearchModule,
  MedIconArrowDownModule,
  MedIconArrowUpModule,
} from "@mk/med-icons";
import { InputComponent } from "./input.component";

@NgModule({
  declarations: [InputComponent],
  imports: [
    CommonModule,
    MedIconSearchModule,
    MedIconArrowDownModule,
    MedIconArrowUpModule,
    FormsModule,
    CheckboxModule,
  ],
  exports: [InputComponent],
})
export class InputModule {}
