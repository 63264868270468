import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { env } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalRedistributionService {
  constructor(private http: HttpClient) {}

  saveRedistributionOffer(payload: {
    id: number;
    amount: number;
  }): Observable<any> {
    return this.http.post<any>(env.apiUrl + "/accounting/v1/redistribution-offer", {
      medicineMedicalDeviceId: payload.id,
      offeredAmount: payload.amount,
    });
  }
}
