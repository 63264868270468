import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-state-switch",
  templateUrl: "./state-switch.component.html",
  styleUrls: ["./state-switch.component.scss"],
})
export class StateSwitchComponent {
  @Output() onAction = new EventEmitter<{ state: 1 | 2 }>();
  activeOption: 1 | 2 = 1;

  toggleSwitch(): void {
    this.activeOption = this.activeOption === 1 ? 2 : 1;
    this.onAction.emit({ state: this.activeOption });
  }
}
