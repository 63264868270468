import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  MedIconChevronDownModule,
  MedIconChevronUpModule,
  MedIconSearchModule,
} from "@mk/med-icons";
import { MultipleLevelListComponent } from "./multiple-level-list.component";
import { TableModule } from "@app/table/table.module";
import { ErrorModule } from "@app/error/error.module";

@NgModule({
  declarations: [MultipleLevelListComponent],
  imports: [
    CommonModule,
    MedIconChevronDownModule,
    MedIconChevronUpModule,
    TableModule,
    MedIconSearchModule,
    FormsModule,
    ErrorModule,
  ],
  exports: [MultipleLevelListComponent],
})
export class MultipleLevelListModule {}
