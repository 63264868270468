import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {DataService} from '@app/shared/data.service';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit {
  @Input() isDisabled?: boolean = false;
  @Output() onAction = new EventEmitter<{ state: boolean }>();
  isActive = false;

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    this.dataService.showMyOrganizationValue.subscribe((isActive) => {
      this.isActive = isActive;
    })
  }

  onShowMyOrganization(): void {
    this.dataService.showMyOrganization = !this.isActive;
  }
}
