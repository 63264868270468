import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { ErrorComponent } from "./error.component";

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, FormsModule],
  exports: [ErrorComponent],
})
export class ErrorModule {}
