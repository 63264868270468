import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MedSignModule } from "@mk/med-sign";
import { SignModalComponent } from "./sign-modal.component";
import { SignModalService } from "./sign-modal.service"; // Adjust the path as necessary

@NgModule({
  declarations: [SignModalComponent],
  imports: [CommonModule, MedSignModule, HttpClientModule],
  exports: [SignModalComponent],
  providers: [SignModalService],
})
export class SignModalModule {}
