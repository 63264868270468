import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastComponent } from "./toast/toast.component";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { SIGN_WIDGET_URL_KEY } from "@mk/med-sign";
import { SignDemoModule } from "@app/sign-demo/sign-demo.module";
import { FormsModule } from "@angular/forms";
import { HeaderComponent } from "@app/header/header.component";
import { SidebarComponent } from "@app/sidebar/sidebar.component";
import { BreadcrumbsModule } from "@app/breadcrumbs/breadcrumbs.module";
import { PageTitleComponent } from "@app/page-title/page-title.component";
import { TableModule } from "@app/table/table.module";
import { AuthModule } from "@app/oauth/auth.module";
import { InitializerService } from "@app/services/initializer.service";
import { env } from "@environments/environment";
import { AuthWithForcedLoginGuard } from "./oauth/auth-with-forced-login.guard";
import { AuthInterceptor } from "./oauth/auth-interceptor";
import { ColumnSortModule } from "./column-sort/column-sort.module";
import { ToggleSwitchComponent } from "./toggle-switch/toggle-switch.component";
import { StateSwitchComponent } from "./state-switch/state-switch.component";
import { MultipleLevelListModule } from "./multiple-level-list/multi-level-list.module";
import { ErrorInterceptor } from "./oauth/error-interceptor";
import { ErrorModule } from "@app/error/error.module";
import { ModalContainerComponent } from "@app/modal/modal-container/modal-container.component";
import { ModalRedistributionComponent } from "./modal/modal-redistribution/modal-redistribution.component";
import { RedistributionsTableComponent } from "./redistributions-table/redistributions-table.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    PageTitleComponent,
    ToggleSwitchComponent,
    StateSwitchComponent,
    ToastComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SignDemoModule,
    BreadcrumbsModule,
    TableModule,
    ColumnSortModule,
    AuthModule.forRoot(),
    MultipleLevelListModule,
    ErrorModule,
    ModalContainerComponent,
    ModalRedistributionComponent,
    RedistributionsTableComponent,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      maxOpened: 10,
      toastComponent: ToastComponent,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthWithForcedLoginGuard,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [InitializerService],
      useFactory: (service: InitializerService) => () => service.init(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: SIGN_WIDGET_URL_KEY,
      useValue: env.signWidgetUrl,
    },
  ],
})
export class AppModule {}
