import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DataService} from './shared/data.service';
import {UserRolesService} from './services/user-roles.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showTable = false;
  showRegistry = false;
  showRedistributions = false;
  navItems = [
    {
      link: '',
      title: 'Реєстр наявних лікарських засобів і медичних виробів',
      subTitle: '',
    },
    {
      link: 'redistributions',
      title: 'Реєстр пропозицій на перерозподіл',
      subTitle: 'Пропозиція на перерозподіл',
    },
    {
      link: 'sign-demo',
      title: 'КЕП Демо',
      subTitle: '',
    },
  ];

  constructor(
    private router: Router,
    private dataService: DataService,
    protected userRole: UserRolesService
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showRegistry = false;
        this.showTable = false;
        this.showRedistributions = false;
        this.dataService.showMyOrganization = false;
      }

      if (this.router.url === '/') {
        this.showRegistry = true;
      }
      if (this.router.url === '/redistributions') {
        this.showRedistributions = true;
      }
    });
  }

  onAppContentChange({state}: { state: 1 | 2 }): void {
    this.showTable = state === 2;
  }
}
