<div class="details-container">
  <div class="details-row header-row">
    <div class="details-row-item">
      <img alt="Header Icon" src="assets/ic-ambulance.svg"/>
      <span class="title extra-small">Пропозиція на перерозподіл</span>
    </div>
    <div class="details-row-item">
      <app-status-label [status]="redistributionDetails?.offerStatus"></app-status-label>
    </div>
    <div class="details-row-item details-row-item-buttons">
      <button (click)="createBooking()" *ngIf="redistributionDetails?.offerStatus === OfferStatusEnum.ACTIVE"
              [disabled]="redistributionDetails.availableAmount < 1 || userOnlyOrganisationIsPropositionSource">
        <span
          [ngClass]="{'button-wide': redistributionDetails.availableAmount < 1}"
          class="button-label button-label-medium">{{ redistributionDetails.availableAmount < 1 ? 'Все заброньовано' : 'Забронювати' }}</span>
        <span class="icon button-label button-label-medium">+</span>
      </button>


      <ng-container *ngIf="isParentOrganisation">
        <button (click)="showRedistributionEdit()"
                *ngIf="redistributionDetails?.offerStatus === OfferStatusEnum.DRAFT || redistributionDetails?.offerStatus === OfferStatusEnum.ACTIVE">
          <span class="button-label button-label-medium">Оновити</span>
          <img alt="Edit Icon" src="assets/icons/edit.svg"/>
        </button>

        <button (click)="showRedistributionActualize()"
                *ngIf="redistributionDetails?.offerStatus === OfferStatusEnum.DRAFT">
              <span class="button-label button-label-medium">Актуалізувати</span
              ><img alt="Accept Icon" src="assets/icons/accept.svg"/>
        </button>
      </ng-container>

    </div>
  </div>
  <div class="details-row">
    <div class="details-row-item">
      <div class="item">
        <span class="header">МНН</span>
        <span class="text">{{
                redistributionDetails?.medicineMedicalDevice?.mnnName || "-"
          }}</span>
      </div>
    </div>
    <div class="details-row-item">
      <div class="item">
        <span class="header">Напрям</span>
        <span class="text">{{
                redistributionDetails?.medicineMedicalDevice?.year +
                ", " +
                redistributionDetails?.medicineMedicalDevice?.directionName || "-"
          }}</span>
      </div>
    </div>
    <div class="details-row-item">
      <div class="item">
        <span class="header">Джерело надходження</span>
        <span class="text">{{
                redistributionDetails?.medicineMedicalDevice?.sponsoredBy || "-"
          }}</span>
      </div>
    </div>
  </div>
  <div class="details-row">
    <div class="details-row-item">
      <div class="item">
        <span class="header">Торгова назва</span>
        <span class="text">{{
                redistributionDetails?.medicineMedicalDevice?.tradeName || "-"
          }}</span>
      </div>
    </div>
    <div class="details-row-item">
      <div class="item-group">
        <div class="item">
          <span class="header">Доступно для бронювання</span>
          <span class="text">
              {{ redistributionDetails.availableAmount }}
            </span>
        </div>
        <div class="item">
          <span class="header">Дозування</span>
          <span class="text">
              {{ redistributionDetails?.medicineMedicalDevice?.dosage || "-" }}
            </span>
        </div>
        <div class="item">
          <span class="header">Придатний до</span>
          <span class="text">
              {{
                  (redistributionDetails?.medicineMedicalDevice?.dueDate
                      | dateString) || "-"
            }}
            </span>
        </div>
      </div>
    </div>
    <div class="details-row-item">
      <div class="item">
        <span class="header">Серія/Каталожний номер</span>
        <span class="text">{{
                redistributionDetails?.medicineMedicalDevice?.series || "-"
          }}</span>
      </div>
    </div>
  </div>


</div>
<ng-container *ngIf="!offersErrorCode">
  <div class="offers-table">
    <table>
      <tr>
        <th class="name">Заклад охорони здоров'я</th>
        <th class="amount">Зарезервовано</th>
        <th class="status">Статус</th>
        <th class="change-date">Дата останньої зміни</th>
        <th class="change-by">Зміни вніс</th>
        <th title="symbol column"></th>
      </tr>
      <tr
        (click)="onOfferClick(offer)"
        (mouseleave)="onOfferHover(null)"
        (mouseover)="onOfferHover(offer)"
        *ngFor="let offer of redistributionOffers"
        [class.active]="activeOffer === offer.id"
      >
        <td class="name">
          <span class="text multiline-ellipsis clamp-1">{{ offer.organizationFullName }}</span>
        </td>
        <td class="amount">{{ offer.bookedAmount }}</td>
        <td class="status">
          <app-booking-status-label [status]="offer.offerStatus"/>
        </td>
        <td class="change-date">{{ offer.modifiedAt | dateLocal | date: "dd-MM-yyyy HH:mm" }}</td>
        <td
          class="change-by">
          <span
            class="text multiline-ellipsis clamp-1">{{ offer.modifiedBy ? offer.modifiedBy.lastName + " " + offer.modifiedBy.firstName : "-" }}</span>
        </td>
        <td class="arrow-icon" title="symbol column">
          <img alt="arrow icon" src="assets/icons/arrow-right.svg"/>
        </td>
      </tr>
    </table>
  </div>
  <div class="pagination-container">
    <med-pagination
      (pageChange)="onPageChange($event)"
      [(page)]="pagination.page"
      [perPage]="pagination.perPage"
      [total]="pagination.totalElements"
    ></med-pagination>
  </div>
</ng-container>
<div *ngIf="offersErrorCode" class="error-container">
  <app-error [errorCode]="offersErrorCode"></app-error>
</div>
<app-modal-redistribution-cancel
    #modalRedistributionCancel
/>
<app-modal-redistribution-edit
    #modalRedistributionEdit
/>
<app-modal-redistribution-actualize #modalRedistributionActualize
                                    [offerId]="redistributionDetails.id"/>

<app-modal-booking-create #modalBookingCreate/>

<app-modal-booking-edit
    #modalBookingEdit
    [isRedistributionOwner]="isRedistributionOwner"
/>

<app-modal-booking-cancel
    #modalBookingCancel
></app-modal-booking-cancel>
