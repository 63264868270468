import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { env } from "@environments/environment";

export type RequestFiltersPayload = {
  [key: string]: any;
  filter: {
    typeId?: number[];
    nomenclatureId?: number[];
    sponsorId?: number[];
    organizationCode?: string[];
    treatmentProgram?: string[];
    dueDateFilter?: {
      from: string;
      to: string;
    };
    balanceQuantityFilter?: {
      from: number;
      to: number;
    };
  };
  pageable: {
    page: number;
    size: number;
  };
  sort: {
    [key: string]: "ASC" | "DESC";
  };
};

export const RequestFilterKeys: { [key: string]: string } = {
  "treatment-program": "treatmentProgram",
  sponsor: "sponsorId",
  "institution-tax": "organizationCode",
  nomenclature: "nomenclatureId",
  "due-date": "dueDateFilter",
  "balance-quantity": "balanceQuantityFilter",
  type: "typeId",
};

@Injectable({
  providedIn: "root",
})
export class TableService {
  constructor(private http: HttpClient) {}

  private defaultRequestFiltersPayload: RequestFiltersPayload = {
    filter: {},
    pageable: {
      page: 0,
      size: 20,
    },
    sort: {
      dueDate: "DESC",
      balanceQuantity: "ASC",
      mnnName: "DESC",
    },
  };

  getBodyData(
    payload: Partial<RequestFiltersPayload> = this.defaultRequestFiltersPayload
  ): Observable<any> {
    const { filter, pageable, sort } = payload;
    const fullPayload = {
      ...this.defaultRequestFiltersPayload.filter,
      ...filter,
    };
    const sortString =
      sort !== undefined
        ? Object.keys(sort as any).length
          ? `&${Object.entries(sort as any)
              .map(([key, value]) => `sort=${key},${value}`)
              .join("&")}`
          : `&sort=mnnName&sort=balanceQuantity,DESC`
        : `&sort=mnnName&sort=balanceQuantity,DESC`;
    return this.http.post<any>(
      env.apiUrl +
        "/accounting/v1/medicines-medical-devices" +
        `?size=${
          pageable?.size || this.defaultRequestFiltersPayload.pageable.size
        }&page=${
          pageable?.page || this.defaultRequestFiltersPayload.pageable.page
        }` +
        sortString,
      fullPayload
    );
  }
}
