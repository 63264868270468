import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { env } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalBookingCreateService {
  constructor(private http: HttpClient) {}

  createBookingOffer(payload: {
    organizationId: number;
    redistributionOfferId: number;
    bookedAmount: number;
  }): Observable<any> {
    return this.http.post<any>(
      env.apiUrl + "/accounting/v1/booked-offer",
      payload
    );
  }
}
