import { NgModule } from "@angular/core";
import { MedIconHomeModule } from "@mk/med-icons";

import { BreadcrumbsComponent } from "./breadcrumbs.component";

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [MedIconHomeModule],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
