<div [style.display]="state.value === 'inactive' ? 'none' : ''">
  <img
    class="error-icon"
    src="assets/icons/alert-error.svg"
    alt="Error Icon"
    width="16px"
    height="16px"
  />
  <img
    class="success-icon"
    src="assets/icons/alert-success.svg"
    alt="Success Icon"
    width="16px"
    height="16px"
  />
  <p class="toast-text">
    {{ title }}
  </p>
  <a (click)="remove()" class="close-btn">
    <img src="assets/icons/x.svg" alt="Close Icon" width="16px" height="16px" />
  </a>
</div>
