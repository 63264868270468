import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  showError(text = "Сталася помилка обробки.\nБудь ласка, спробуйте пізніше.") {
    this.toastr.show("", text, {
      toastClass: "custom-toast-error",
    });
  }

  showSuccess(text = "Запис успішно створено") {
    this.toastr.show("", text, {
      toastClass: "custom-toast-success",
    });
  }
}
