import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { MedLocalizationService } from "@mk/med-localization";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private localization: MedLocalizationService,
    private router: Router,
    private oauthSvc: OAuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe((err) =>
      err.pipe(
        catchError((data) =>
          throwError(() => {
            console.error(data);

            if ([404, 403].includes(data.status) || data.status >= 500) {
              const {
                error: { localizedMessage },
              } = data;
              sessionStorage.setItem(
                "error.description",
                localizedMessage || ""
              );
              this.router.navigate([this.localization.locale, data.status]);

              return data;
            }

            if (data.status === 401) {
              const { pathname, search } = location;
              const path = pathname.split("/").splice(2).join("/");
              this.oauthSvc.initCodeFlow(`${path}${search}`);

              return data;
            }
            return data;
          })
        )
      )
    );
  }
}
