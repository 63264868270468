import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BookingStatusEnum} from '@app/enums/booking-status.enum';

@Component({
  selector: 'app-booking-status-label',
  templateUrl: './booking-status-label.component.html',
  styleUrls: ['./booking-status-label.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class BookingStatusLabelComponent {
  @Input() status!: BookingStatusEnum | null;

  statusMapping = {
    [BookingStatusEnum.ACTIVE]: 'Актуально',
    [BookingStatusEnum.CONFIRMED]: 'Підтверджено',
    [BookingStatusEnum.CANCELLED]: 'Скасовано',
  }
}
