export const tableHeader = [
  { key: "mnnName", label: "МНН" },
  { key: "tradeName", label: "Торгова назва" },
  { key: "type", label: "Тип" },
  { key: "dosage", label: "Дозування" },
  { key: "series", label: "Серія/Серійний \nномер" },
  { key: "dueDate", label: "Придатний до" },
  { key: "balanceQuantity", label: "Залишки" },
  { key: "availableQuantity", label: "Доступні \nзалишки" },
  { key: "minUnit", label: "Мін. облікова \nодиниця" },
  { key: "organizationCode", label: "Код ЄДРПОУ/ \nРНОКПП" },
  { key: "institutionName", label: "Назва закладу" },
  { key: "sponsoredBy", label: "Джерело \nнадходження" },
  // { key: "directionName", label: "Напрям" },
  { key: "treatmentProgram", label: "Напрям" },
  { key: "quarantineQuantity", label: "На карантині" },
  { key: "redistributionOffer", label: "Запропоновано \nна перерозподіл" },
  { key: "bookedQuantity", label: "Зарезервовано \nна перерозподіл" },
  { key: "editedOn", label: "Дата оновлення \nзапису" },
  { key: "administrationName", label: "Регіональне \nуправління" },
  { key: "productionDate", label: "Дата \nвиробництва" },
  { key: "atcCode", label: "Код АТС (АТХ)" },
  { key: "releaseForm", label: "Форма випуску" },
  { key: "pharmaName", label: "Виробник" },
  { key: "registrationCertificateNumber", label: "Номер РП" },
  // {
  //   key: "lovId",
  //   label:
  //     "Унікальний ідентифікатор номенклатурної позиції, незалежно від року",
  // },
  // { key: "treatmentProgram", label: "Напрям закупівель" },
  // { key: "year", label: "Рік" },
  // { key: "pharmaId", label: "Ідентифікатор Виробника" },
  // { key: "organizationId", label: "Ідентифікатор Закладу" },
  // { key: "id", label: "Ідентифікатор запису" },
  // { key: "sponsorId", label: "Ідентифікатор Джерела надходження" },
  // { key: "typeId", label: "Ідентифікатор ЛЗ" },
  // { key: "nomenclatureId", label: "Ідентифікатор МНН" },
];
