import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignDemoComponent } from "./sign-demo.component";

import { SIGN_WIDGET_URL_KEY } from "@mk/med-sign";
import { SignModalModule } from "./components/sign-modal/sign-modal.module";
import { FormsModule } from "@angular/forms";
import { env } from "@environments/environment";

@NgModule({
  declarations: [SignDemoComponent],
  imports: [FormsModule, SignModalModule, CommonModule],
  exports: [SignDemoComponent],
  providers: [
    {
      provide: SIGN_WIDGET_URL_KEY,
      useValue: env.signWidgetUrl,
    },
  ],
})
export class SignDemoModule {}
