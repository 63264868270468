<div class="sidebar-container">
  <div class="sidebar-header">
    <img
      src="assets/icons/sidebar.svg"
      width="20px"
      height="20px"
      alt="Sidebar Icon"
    />
    <span>Облік</span>
  </div>

  <div class="sidebar-list">
    <a
      *ngFor="let item of items"
      class="sidebar-item"
      [routerLink]="'/' + item['link']"
      [class.active]="isActive(item['link'])"
      >{{ item["title"] }}</a
    >
  </div>
</div>
