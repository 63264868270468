import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "med-pagination",
  templateUrl: "./med-pagination.component.html",
  styleUrls: ["./med-pagination.component.scss"],
})
export class MedPaginationComponent implements AfterViewInit {
  @Input() radius = 2;
  @Input() perPage!: number;
  @Input() total!: number;
  @Input() page!: number;

  @Output() pageChange = new EventEmitter<number>();
  @Output() isLastPage = new EventEmitter<boolean>();

  // After the index of the first element
  readonly AFTER_FIRST_ELEMENTS_INDEX: number = 2;

  // Delta between page and array
  readonly DELTA: number = 1;

  readonly FIRST_PAGE_INDEX: number = 0;

  // D = 2 * R
  get diameter(): number {
    return this.radius * 2;
  }

  // Number of pages
  get countOfPages(): number {
    return Math.ceil(this.total / this.perPage) || 0;
  }

  get renderBtnsArray(): number[] {
    const array = [this.DELTA];
    const firstPage = this.page - this.getDynamicRadius;
    const lastPage = this.page + this.getDynamicRadius;
    for (let i = firstPage; i <= lastPage; i++) {
      if (i >= this.DELTA && i <= this.countOfPages) {
        array.push(i);
      }
    }
    array.push(this.countOfPages);
    return array.filter((item: number, index: number) => array.indexOf(item) === index);
  }

  private get pageIndex(): number {
    return this.page - this.DELTA;
  }
  // Dynamic radius to distribute the visibility of points
  private get getDynamicRadius(): number {
    if (this.pageIndex === this.FIRST_PAGE_INDEX
      || this.pageIndex === this.countOfPages - this.DELTA) {
      return this.diameter;
    }

    if (this.pageIndex === this.DELTA
      || this.pageIndex === this.countOfPages - this.AFTER_FIRST_ELEMENTS_INDEX) {
      return this.diameter - this.DELTA;
    }

    return this.radius;
  }

  // Bottom of visible border elements
  private get bottomOfBorder(): number {
    return this.pageIndex - this.renderBtnsArray.length;
  }

  // Top of visible border elements
  private get topOfBorder(): number {
    return this.pageIndex + this.renderBtnsArray.length;
  }

  /*--- SHOW OR HIDE BUTTONS ---*/
  hasHideNavBtn(page: number): boolean {
    const pageIndex: number = page - this.DELTA;
    return !(pageIndex >= this.bottomOfBorder && pageIndex <= this.topOfBorder)
      && !this.hasFirstOfLastPage(page);
  }

  ngAfterViewInit(): void {
    this.emitHasLastPage();
  }

  hasRenderDots(index: number): boolean {
    const sufficientNumberOfElements: boolean = this.countOfPages > this.diameter; // sufficient number of the elements
    const isSecondIndex: boolean = index === this.DELTA; // should render after first of the element
    const isBeforeListIndex: boolean = index === this.renderBtnsArray.length - this.DELTA; // should render before last of the element
    return sufficientNumberOfElements
      && (isSecondIndex || isBeforeListIndex);
  }

  hasHideDot(index: number): boolean {
    if (index === this.DELTA) {
      return this.renderBtnsArray.indexOf(this.AFTER_FIRST_ELEMENTS_INDEX) >= this.FIRST_PAGE_INDEX;
    }
    return this.renderBtnsArray.indexOf(this.countOfPages - this.DELTA) >= this.FIRST_PAGE_INDEX;
  }

  hasFirstOfLastPage(page: number): boolean {
    return page === this.DELTA || page === this.countOfPages;
  }

  /*--- EMIT OF PAGE NUMBER ---*/
  nextPage(): void {
    if (this.page < this.countOfPages) {
      const currentPage: number = this.page + this.DELTA;
      this.emitChange(currentPage);
    }
  }

  prevPage(): void {
    if (this.page > this.DELTA) {
      const currentPage: number = this.page - this.DELTA;
      this.emitChange(currentPage);
    }
  }

  updatePage(index: number): void {
    if (index !== this.page) {
      this.emitChange(index);
    }
  }

  private emitChange(currentPage: number) {
    this.emitHasLastPage();
    this.pageChange.emit(currentPage);
  }

  private emitHasLastPage() {
    this.isLastPage.emit(this.page === this.countOfPages);
  }
}
