import { Component } from "@angular/core";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  protected error = {
    title: "Ресурс не знайдено",
    description:
      "Будь ласка, перевірте коректність запиту або зверніться до адміністратора",
  };
}
