import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class DataService {
  showMyOrganizationValue = new BehaviorSubject(
    this.showMyOrganization || false
  );
  organizationCodeValue = new BehaviorSubject(this.organizationCode || "");
  userRoleValue = new BehaviorSubject(this.userRole || "");
  userOrganizationsValue = new BehaviorSubject(this.userOrganizations || []);
  allOrganizationsValue = new BehaviorSubject(
    this.allOrganizations || {
      data: [],
      errorCode: 0,
    }
  );
  isExpiredValue = new BehaviorSubject(this.isExpired || false);

  set userOrganizations(value: Array<{ [key: string]: string | number }>) {
    this.userOrganizationsValue.next(value);
  }

  set allOrganizations({
    data = [],
    errorCode = 0,
  }: {
    data?: any;
    errorCode?: number;
  }) {
    this.allOrganizationsValue.next({ data, errorCode });
  }

  set showMyOrganization(value: boolean) {
    this.showMyOrganizationValue.next(value);
  }

  set userRole(value: string) {
    this.userRoleValue.next(value);
  }

  set organizationCode(value: string) {
    this.organizationCodeValue.next(value);
  }

  set isExpired(value: boolean) {
    this.isExpiredValue.next(value);
  }
}
