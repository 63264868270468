import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Dropdown} from 'bootstrap';
import {debounceTime, distinctUntilChanged, fromEvent, Subscription,} from 'rxjs';
import {CheckboxModule} from '@app/checkbox/checkbox.module';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, CheckboxModule],
})
export class DropdownComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input() multipleSelect?: boolean = false;
  @Input() placeholder?: string = '';
  @Input() items: string[] = [];
  @Input() isError: boolean = false;
  @Output() itemSelected = new EventEmitter<string>();
  @ViewChild('searchInput', {static: true}) searchInput!: ElementRef;
  @ViewChild('dropdown', {static: true}) dropdownRef!: ElementRef;
  protected dropdownButtonDefaultText: string = '';
  protected dropdownButtonText: string = '';
  protected searchText: string = '';
  protected checkedList: { [key: string]: boolean } = {};
  protected allChecked: boolean = false;
  protected checkedCounter: number = 0;
  private searchSubscription!: Subscription;
  private dropdown!: Dropdown;
  private defaultItems: string[] = [];

  @Input() set defaultSelectedValues(values: string[]) {
    const defaultChecked = values?.reduce((acc: { [key: string]: boolean }, curr) => {
      acc[curr] = true;
      return acc;
    }, {}) || {};

    // must be async to let other inputs settle
    setTimeout(() => this.onCheckedChange(defaultChecked))
  } ;

  ngOnInit() {
    this.dropdownButtonDefaultText = this.placeholder || '';
  }

  ngAfterViewInit() {
    this.searchSubscription = fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(500), // Adjust the debounce time as needed
        distinctUntilChanged()
      )
      .subscribe((event) => this.onSearchInputChange(event as Event));

    this.dropdown = new Dropdown(this.dropdownRef.nativeElement, {
      popperConfig: {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkedList = {};
    if (changes['items'] && changes['items'].currentValue) {
      this.defaultItems = changes['items'].currentValue;
    }
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  onCheckAll(): void {
    this.allChecked = !this.allChecked;
    const checkedUpdate: { [key: string]: boolean } = this.items.reduce(
      (acc: { [key: string]: boolean }, item) => {
        acc[item] = this.allChecked;
        return acc;
      },
      {}
    );
    this.onCheckedChange(checkedUpdate);
  }

  onCheckedChange(checkedUpdate: { [key: string]: boolean }): void {
    this.isError = false;
    this.checkedList = {...this.checkedList, ...checkedUpdate};

    const checkedItems = Object.values(this.checkedList).filter(
      (option) => option
    );
    this.allChecked = checkedItems.length === this.items.length;
    this.checkedCounter = checkedItems.length - 1;

    this.dropdownButtonText =
      Object.keys(this.checkedList).filter((key) => this.checkedList[key])[0] ||
      '';
    this.itemSelected.emit(
      Object.keys(this.checkedList)
        .filter((key) => this.checkedList[key])
        .join('&')
    );

  }

  dropdownOpen(event: MouseEvent): void {
    event.stopPropagation();
    this.searchText = '';
    this.items = this.defaultItems;
    const dropdownElement = this.dropdownRef.nativeElement;
    if (dropdownElement.classList.contains('show')) {
      this.dropdown.hide();
    } else {
      document.querySelectorAll('.dropdown-toggle.show')?.forEach(() => {
        document.querySelector('body')?.click();
      });
      this.dropdown.show();
    }
  }

  onSearchInputChange(event: Event): void {
    if (!this.searchText) {
      this.items = this.defaultItems;
      return;
    }
    this.items = this.defaultItems.filter((item) =>
      item.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  onItemSelected(item: string): void {
    this.isError = false;
    this.dropdownButtonText = item;
    this.itemSelected.emit(item);
  }
}
