import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() items: { [key: string | number]: string }[] = [];
  title: string = "";

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.getCurrentItem();
    this.router.events.subscribe(() => {
      this.getCurrentItem();
    });
  }

  getCurrentItem(): void {
    const currentItem = this.items.find(
      (item) => item["link"] === this.router.url
    );
    this.title = currentItem ? currentItem["title"] : "";
  }
}
