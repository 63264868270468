import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { env } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class SignModalService {
  constructor(private http: HttpClient) {}

  hashString(text: string): Observable<string> {
    return this.http.post(
      env.apiUrl + "/accounting/v1/signed/data/hashed",
      text,
      {
        responseType: "text",
      }
    );
  }

  verifyString(text: string): Observable<string> {
    return this.http.post<string>(
      env.apiUrl + "/accounting/v1/signed/data/verified",
      text
    );
  }
}
