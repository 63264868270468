<ul class="med-pagination text small">
  <li class="med-pagination__item">
    <button
      class="med-pagination__btn prev"
      (click)="prevPage()"
      title="Попередня сторінка"
    >
      <med-icon-chevron-left size="16"></med-icon-chevron-left>
    </button>
  </li>

  <ng-container *ngFor="let btnIndex of renderBtnsArray; let index = index">
    <li
      *ngIf="hasRenderDots(index) && !hasHideDot(index)"
      class="med-pagination__item med-pagination__desktop"
    >
      <div class="med-pagination__dots">...</div>
    </li>

    <li
      [hidden]="hasHideNavBtn(btnIndex)"
      class="med-pagination__item med-pagination__desktop"
    >
      <button
        class="med-pagination__btn"
        [ngClass]="{ active: btnIndex === page }"
        (click)="updatePage(btnIndex)"
      >
        {{ btnIndex }}
      </button>
    </li>
  </ng-container>

  <li class="med-pagination__item med-pagination__mobile">
    <div class="med-pagination__dots">{{ page }} з {{ countOfPages }}</div>
  </li>

  <li class="med-pagination__item">
    <button
      class="med-pagination__btn next"
      (click)="nextPage()"
      title="Наступна сторінка"
    >
      <med-icon-chevron-right size="16"></med-icon-chevron-right>
    </button>
  </li>
</ul>
