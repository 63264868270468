import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateString",
  pure: true,
  standalone: true,
})
export class DateStringPipe implements PipeTransform {
  transform(value: string | undefined, dateStringLength = 10): string {
    if (!value) {
      return "-";
    }

    const isoDatePattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
    if (isoDatePattern.test(value)) {
      return value && value.length > dateStringLength
        ? value.slice(0, dateStringLength)
        : value;
    }
    return value;
  }
}
