import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {ModalContainerComponent} from '@app/modal/modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {
  ModalRedistributionEditService
} from '@app/modal/modal-redistribution/modal-redistribution-edit/modal-redistribution-edit.service';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';

@Component({
  standalone: true,
  selector: 'app-modal-redistribution-cancel',
  templateUrl: './modal-redistribution-cancel.component.html',
  styleUrls: ['./modal-redistribution-cancel.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    LoadingAnimationComponent,
    FormsModule,
  ],
})
export class ModalRedistributionCancelComponent {
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  @Output() hidden = new EventEmitter<void>();
  @Output() visible = new EventEmitter<void>();
  protected cancelReason: string = '';
  protected inputErrorText: string = '';
  protected loadingStatus: boolean = false;
  private offeringId?: number;

  constructor(
    private toastService: ToastService,
    private modalRedistributionEditService: ModalRedistributionEditService
  ) {
  }

  @Input() returnBackAction = () => {
  };

  reset() {
    this.cancelReason = '';
    this.inputErrorText = '';
    this.loadingStatus = false;
  }

  open(id: number) {
    this.offeringId = id;
    this.reset();
    this.visible.emit();
    this.modalContainer.openModal();
  }

  close() {
    this.hidden.emit();
  }

  cancel() {
    if (!this.cancelReason) {
      this.inputErrorText = 'Поле обовʼязкове до заповнення';
      return;
    }
    this.loadingStatus = true;
    this.modalRedistributionEditService
      .editRedistributionOffer({
        id: this.offeringId!,
        reasonForCancellation: this.cancelReason,
        offerStatus: OfferStatusEnum.CANCELLED
      })
      .subscribe({
          next: () => {
            this.loadingStatus = false;
            this.modalContainer.close();
            this.toastService.showSuccess('Запис успішно оновлено');
          }, error: (error) => {
            this.loadingStatus = false;
            this.modalContainer.close();
            if (error.status >= 500) {
              this.toastService.showError(
                'Сервер не доступний.\nСпробуйте пізніше, або зверніться до адміністратора.'
              );
            } else {
              this.toastService.showError();
            }
          }
        },
      );
  }
}
