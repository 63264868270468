import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MedIconResetModule } from "@mk/med-icons";

import { TableComponent } from "./table.component";
import { MedPaginationModule } from "@med-ui/med-pagination/med-pagination.module";
import { FilterModule } from "@app/filter/filter.module";
import { TooltipModule } from "@app/tooltip/tooltip.module";
import { ErrorModule } from "@app/error/error.module";
import { ModalRedistributionCreateComponent } from "../modal/modal-redistribution/modal-redistribution-create/modal-redistribution-create.component";
import { TextEllipsisPipe } from "@app/pipes/text-ellipsis.pipe";
import { DateStringPipe } from "@app/pipes/date-string.pipe";
import { ContextMenuComponent } from "@app/context-menu/context-menu.component";

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    MedPaginationModule,
    MedIconResetModule,
    FilterModule,
    TooltipModule,
    ErrorModule,
    ModalRedistributionCreateComponent,
    TextEllipsisPipe,
    DateStringPipe,
    ContextMenuComponent,
  ],
  exports: [TableComponent],
})
export class TableModule {}
