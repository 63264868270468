import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  Renderer2,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FilterInitDataKeys,
  DoubleFilterKeys,
  FilterService,
  SortKeys,
} from "./filter.service";
import { createISODateFromString } from "@app/utils/date.util";
import { Dropdown } from "bootstrap";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, AfterViewInit {
  @Input() reset: boolean = false;
  @Input() key: FilterInitDataKeys = "";
  @Input() sortKey?: string = "";
  @Input() preselectedOption?: { [key: string]: any } = {};
  @Output() selectedOptionsChange = new EventEmitter<{
    key: string;
    payload: string[] | { [key: string]: any };
  }>();
  @Output() sortChange = new EventEmitter<{ [key: string]: "ASC" | "DESC" }>();
  @Output() onError = new EventEmitter<{ [key: string]: any }>();
  @ViewChild("dropdown", { static: true }) dropdownRef!: ElementRef;

  doubleFilterKeys = DoubleFilterKeys;
  sortKeys = SortKeys;
  filterOptions: Array<any> = [];
  visibleOptions: Array<any> = [];
  isDoubleInputFilter: boolean = false;
  filterPlaceholders: { placeholder: string; placeholder2: string } = {
    placeholder: "",
    placeholder2: "",
  };
  maxSymbols: number = 999;
  isActive: boolean = false;

  private dropdown!: Dropdown;

  constructor(
    private filterService: FilterService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this.key.length) {
      this.filterService.getInitialFilterData(this.key)?.subscribe(
        (filtersData: string[]) => {
          if (!filtersData || !filtersData.length) return;
          if (this.preselectedOption && this.preselectedOption[this.key]) {
            this.filterOptions = [
              filtersData.find(
                (option) =>
                  this.preselectedOption &&
                  option === this.preselectedOption[this.key]
              ),
            ];
          } else {
            this.filterOptions = filtersData;
          }
          this.visibleOptions = [...this.filterOptions];
        },
        (error) => {
          this.onError.emit({
            text: "Error fetching filters data",
            payload: error,
          });
          console.error("Error fetching filters data", error);
        }
      );
    }
    this.isDoubleInputFilter = this.doubleFilterKeys.includes(this.key);
    this.maxSymbols = this.doubleFilterKeys.includes(this.key) ? 7 : 999;
    this.filterPlaceholders = {
      placeholder: this.isDoubleInputFilter
        ? this.key === "due-date"
          ? "Від (ММ.РРРР)"
          : "Від"
        : "Пошук",
      placeholder2: this.isDoubleInputFilter
        ? this.key === "due-date"
          ? "До (ММ.РРРР)"
          : "До"
        : "",
    };
  }

  ngAfterViewInit(): void {
    this.dropdown = new Dropdown(this.dropdownRef.nativeElement, {
      popperConfig: {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ],
      },
    });
  }

  getListToRender(): string[] {
    if (typeof this.visibleOptions[0] === "string") {
      return this.visibleOptions;
    }

    switch (this.key) {
      case "sponsor":
        return this.visibleOptions.map((option) => option.sponsoredBy);
      case "nomenclature":
        return this.visibleOptions.map((option) => option.mnnName);
      case "type":
        return this.visibleOptions.map((option) => option.name);
      default:
        return [];
    }
  }

  searchOptions(searchString: string): void {
    if (!searchString) {
      this.visibleOptions = [...this.filterOptions];
      return;
    }
    this.visibleOptions = this.filterOptions.filter((option) => {
      if (typeof option === "string") {
        return option.toLowerCase().includes(searchString.toLowerCase());
      }
      switch (this.key) {
        case "sponsor":
          return option.sponsoredBy
            .toLowerCase()
            .includes(searchString.toLowerCase());
        case "nomenclature":
          return option.mnnName
            .toLowerCase()
            .includes(searchString.toLowerCase());
        case "type":
          return option.name.toLowerCase().includes(searchString.toLowerCase());
        default:
          return false;
      }
    });
  }

  onCheckedListChange(checkedUpdate: string[]): void {
    if (!checkedUpdate.length) {
      this.reset = true;
      setTimeout(() => {
        this.reset = false;
      }, 0);
    }
    this.isActive = checkedUpdate.length > 0;
    switch (this.key) {
      case "sponsor":
        this.selectedOptionsChange.emit({
          key: this.key,
          payload: checkedUpdate.map(
            (option) =>
              this.filterOptions.find((item) => item.sponsoredBy === option)
                .sponsorId
          ),
        });
        break;
      case "nomenclature":
        this.selectedOptionsChange.emit({
          key: this.key,
          payload: checkedUpdate.map(
            (option) =>
              this.filterOptions.find((item) => item.mnnName === option)
                .nomenclatureId
          ),
        });
        break;
      case "type":
        this.selectedOptionsChange.emit({
          key: this.key,
          payload: checkedUpdate.map(
            (option) =>
              this.filterOptions.find((item) => item.name === option).id
          ),
        });
        break;
      default:
        this.selectedOptionsChange.emit({
          key: this.key,
          payload: checkedUpdate,
        });
    }
  }

  onSearchInputChange(
    payload: string | { from?: string; to?: string; isExpired?: boolean }
  ): void {
    if (typeof payload === "string") this.searchOptions(payload as string);
    else {
      this.isActive = !!Object.values(payload).find(
        (value) =>
          value === true || (typeof value === "string" && value.length > 0)
      );
      if (this.key === "due-date") {
        this.selectedOptionsChange.emit({
          key: this.key,
          payload: {
            ...(payload.from && {
              from: createISODateFromString(payload.from),
            }),
            ...(payload.to && { to: createISODateFromString(payload.to) }),
          },
        });
      } else {
        this.selectedOptionsChange.emit({
          key: this.key,
          payload,
        });
      }
    }
  }

  onSortChange(payload: { [key: string]: "ASC" | "DESC" }): void {
    this.sortChange.emit(payload);
  }

  inputCheckFunction(keyword: string): boolean {
    switch (this.key) {
      case "due-date":
        if (keyword.length === 0) return true;
        const dateFormatRegex = /^(?:0[1-9]|1[0-2])[./-]\d{4}$|^$/;
        return dateFormatRegex.test(keyword.trim());
      case "balance-quantity":
      case "institution-tax":
        const numbersOnlyRegex = /^\d*$/;
        return numbersOnlyRegex.test(keyword.trim());
      default:
        return true;
    }
  }

  getErrorMessage(): string {
    switch (this.key) {
      case "due-date":
        return "Неправильний формат дати. Введіть дату у форматі ММ.РРРР (місяць.рік).";
      case "balance-quantity":
      case "institution-tax":
        return "Введені дані некоректні. Перевірте їх та спробуйте ще раз";
      default:
        return "Введені дані некоректні. Перевірте їх та спробуйте ще раз.";
    }
  }

  isColumnSortable(columnKey: string): boolean {
    return (
      Object.keys(this.sortKeys).includes(columnKey) &&
      columnKey === this.sortKey
    );
  }

  dropdownOpen(event: MouseEvent): void {
    const dropdownElement = this.dropdownRef.nativeElement;
    if (dropdownElement.classList.contains("show")) {
      this.dropdown.hide();
    } else {
      document.querySelectorAll(".dropdown-toggle.show")?.forEach(() => {
        document.querySelector("body")?.click();
      });
      this.dropdown.show();
    }
  }
}
