<app-modal-container
    #modalContainer
    (onSave)="save()"
    [height]="532"
>
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <div class="modal-header">
      <img alt="Header Icon" src="assets/ic-comments.svg"/>
      <p>Керувати бронюванням пропозиції</p>
      <button (click)="cancel()" *ngIf="!isRedistributionOwner" type="button">
        <span>Скасувати</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="details">
        <div class="row">
          <div class="right">
            <div class="item">
              <span class="header">Статус</span>
              <app-booking-status-label [status]="fields?.offer.offerStatus"/>
            </div>
          </div>
          <div class="left">
            <div class="item">
              <span class="header">Напрям</span>
              <span
                [title]="fields?.year + ', ' + fields?.directionName"
                class="text"
              >{{ fields?.year + ", " + fields?.directionName }}</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="right">
            <div class="item">
              <span class="header">МНН</span>
              <span [title]="fields?.mnnName" class="text multiline-ellipsis">{{
                      fields?.mnnName
                }}</span>
            </div>
          </div>
          <div class="left">
            <div class="item">
              <span class="header">Дозування</span>
              <span [title]="fields?.dosage" class="text multiline-ellipsis clamp-1">{{
                      fields?.dosage || "-"
                }}</span>
            </div>
            <div class="item">
              <span class="header">Серія/Каталожний номер</span>
              <span [title]="fields?.series" class="text">{{
                      fields?.series || "-"
                }}</span>
            </div>
            <div class="item">
              <span class="header">Придатний до</span>
              <span [title]="fields?.dueDate | formatISODate" class="text">{{
                      fields?.dueDate | formatISODate
                }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="right">
            <div class="item">
              <span class="header">Торгова назва</span>
              <span [title]="fields?.tradeName" class="text multiline-ellipsis">{{
                      fields?.tradeName
                }}</span>
            </div>
          </div>
          <div class="left">
            <div class="item">
              <span class="header">Запропоновано на перерозподіл</span>
              <span [title]="fields?.redistributionOffer" class="text">{{
                      fields?.redistributionOffer || "-"
                }}</span>
            </div>
            <div class="item">
              <span class="header">Джерело надходжень</span>
              <span [title]="fields?.sponsoredBy" class="text multiline-ellipsis">{{
                      fields?.sponsoredBy || "-"
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row inputs-row">
        <div class="right">
          <div class="item">
            <span class="header">Кількість</span>
            <span>{{ fields?.offer?.bookedAmount || "-" }}</span>
          </div>
        </div>
        <div class="left">
          <div class="item">
            <span class="header">Сповістити</span>
            <span>{{ fields?.offer?.organizationFullName || "-" }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isRedistributionOwner" class="modal-footer">
      <button
        (click)="cancel()"
        class="close-btn"
        type="button"
      >
        <span>Відхилити</span>
        <img alt="Comment Icon" src="assets/icons/comment.svg"/>
      </button>
      <button (click)="save()" [hidden]="fields?.offer.offerStatus !== BookingStatusEnum.ACTIVE" class="save-btn"
              type="button">
        <span>Підтвердити</span>
        <img alt="Comment Icon" src="assets/icons/check-circle.svg"/>
      </button>
    </div>
  </ng-container>
</app-modal-container>
