<app-modal-container
  #modalContainer
  (onSave)="cancel()"
  (onClose)="close()"
  [width]="536"
>
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation class="loading"></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <div class="modal-header">
      <img src="assets/ic-error.svg" alt="Header Icon" />
      <p>Скасувати пропозицію</p>
    </div>
    <div class="modal-body">
      <div class="row inputs-row">
        <div class="right">
          <div class="item">
            <span class="header">Причина скасування</span>
            <textarea
              class="text"
              [class.error]="inputErrorText.length"
              [(ngModel)]="cancelReason"
              (input)="inputErrorText = ''"
              [placeholder]="'Причина скасування'"
            ></textarea>
            <span class="error-text" *ngIf="inputErrorText.length">{{
              inputErrorText
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="close-btn"
        data-bs-dismiss="modal"
        (click)="returnBackAction()"
      >
        <span>Відмінити</span>
      </button>
      <button type="button" class="save-btn" (click)="cancel()">
        <span>Підтвердити</span>
      </button>
    </div>
  </ng-container>
</app-modal-container>
