import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignDemoComponent } from "./sign-demo/sign-demo.component";
import { TableComponent } from "./table/table.component";
import { MultipleLevelListComponent } from "./multiple-level-list/multiple-level-list.component";
import { AuthWithForcedLoginGuard } from "@app/oauth/auth-with-forced-login.guard";
import { RedistributionsTableComponent } from "./redistributions-table/redistributions-table.component";

const routes: Routes = [
  {
    path: "",
    component: TableComponent,
    canActivate: [AuthWithForcedLoginGuard],
    outlet: "table",
  },
  {
    path: "",
    component: MultipleLevelListComponent,
    canActivate: [AuthWithForcedLoginGuard],
    outlet: "list",
  },
  {
    path: "redistributions",
    component: RedistributionsTableComponent,
    canActivate: [AuthWithForcedLoginGuard],
  },
  {
    path: "sign-demo",
    component: SignDemoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
