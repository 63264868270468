import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  OnChanges,
  OnInit,
} from "@angular/core";
import { createDateFromString } from "@app/utils/date.util";
import { DataService } from "@app/shared/data.service";

const FROM_TO_ERROR = "До має бути більше за Від";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnChanges, OnInit {
  @Input() key: string = "";
  @Input() isReset: boolean = false;
  @Input() onCheck: (keyword: string) => boolean = () => true;
  @Input() placeholder: string = "";
  @Input() placeholder2: string = "";
  @Input() errorMessage: string = "";
  @Input() doubleInput: boolean = false;
  @Input() maxSymbols: number = 999;
  @Output() onAction = new EventEmitter<
    string | { from?: string; to?: string }
  >();

  @ViewChild("inputRef") inputRef!: ElementRef;
  @ViewChild("minInputRef") minInputRef!: ElementRef;
  @ViewChild("maxInputRef") maxInputRef!: ElementRef;

  protected isError: boolean = false;
  protected isSixMonthsExpiration: boolean = false;

  private inputValue: string = "";
  private inputValueMin: string = "";
  private inputValueMax: string = "";
  private errorMessageLocal: string = "";

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.errorMessageLocal = this.errorMessage;
  }

  ngOnChanges(): void {
    if (this.isReset) {
      this.inputValue = "";
      if (this.inputRef) this.inputRef.nativeElement.value = "";
      this.onDeselectAll();
    }
  }

  inputAction(): void {
    if (
      (this.inputValueMin.length || this.inputValueMax.length) &&
      this.doubleInput
    ) {
      if (!this.onCheck(this.inputValueMin)) {
        this.isError = true;
        this.errorMessage = this.errorMessageLocal;
        return;
      }
      if (!this.onCheck(this.inputValueMax)) {
        this.isError = true;
        this.errorMessage = this.errorMessageLocal;
        return;
      }
    }
    if (this.inputValueMin.length && this.inputValueMax.length) {
      if (
        (this.key === "due-date" &&
          createDateFromString(this.inputValueMin) >
            createDateFromString(this.inputValueMax)) ||
        (this.key === "balance-quantity" &&
          Number(this.inputValueMin) > Number(this.inputValueMax))
      ) {
        this.isError = true;
        this.errorMessage = FROM_TO_ERROR;
        return;
      }
    }
    if (this.inputValue) {
      const checkResult = this.onCheck(this.inputValue);
      if (!checkResult) {
        this.isError = true;
        this.errorMessage = this.errorMessageLocal;
        return;
      }
    }
    this.isError = false;

    let payload: string | { from?: string; to?: string; isExpired?: boolean } =
      !this.doubleInput
        ? this.inputValue
        : {
            ...(this.inputValueMin && { from: this.inputValueMin }),
            ...(this.inputValueMax && { to: this.inputValueMax }),
            isExpired: this.isSixMonthsExpiration,
          };
    this.onAction.emit(payload);
  }

  onInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.inputValue = target.value;
  }

  onMinInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.inputValueMin = target.value;
    this.isSixMonthsExpiration = false;
    this.dataService.isExpired = false;
  }

  onMaxInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.inputValueMax = target.value;
    this.isSixMonthsExpiration = false;
    this.dataService.isExpired = false;
  }

  setMinValue(): void {
    this.inputValueMin = "0";
    this.minInputRef.nativeElement.value = "0";
    this.inputAction();
  }

  setMaxValue(): void {
    this.inputValueMax = "99999";
    this.maxInputRef.nativeElement.value = "99999";
    this.inputAction();
  }

  onDeselectAll(): void {
    if (this.minInputRef && this.maxInputRef) {
      this.minInputRef.nativeElement.value = "";
      this.maxInputRef.nativeElement.value = "";
    }
    this.isSixMonthsExpiration = false;
    this.dataService.isExpired = false;
    this.inputValueMin = "";
    this.inputValueMax = "";
    this.inputAction();
    this.isError = false;
  }

  onSelectSixMonthsExpiration(isChecked: Record<string, boolean>): void {
    if (Object.values(isChecked)[0]) {
      this.isSixMonthsExpiration = true;
      this.dataService.isExpired = true;
      if (this.minInputRef && this.maxInputRef) {
        this.minInputRef.nativeElement.value = "";
        this.maxInputRef.nativeElement.value = "";
      }
      this.inputValueMin = "";
      this.inputValueMax = "";
      this.inputAction();
    } else {
      this.onDeselectAll();
    }
  }
}
