import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {RedistributionDetailsService} from '@app/redistribution-details/redistribution-details.service';
import {DataService} from '@app/shared/data.service';
import {
  ModalBookingCreateComponent
} from '@app/modal/modal-booking/modal-booking-create/modal-booking-create.component';
import {DateStringPipe} from '../pipes/date-string.pipe';
import {CommonModule} from '@angular/common';
import {ErrorModule} from '../error/error.module';
import {MedPaginationModule} from '@med-ui/med-pagination/med-pagination.module';
import {ModalBookingEditComponent} from '../modal/modal-booking/modal-booking-edit/modal-booking-edit.component';
import {
  ModalRedistributionCancelComponent
} from '@app/modal/modal-redistribution/modal-redistribution-cancel/modal-redistribution-cancel.component';
import {
  ModalRedistributionActualizeComponent
} from '@app/modal/modal-redistribution/modal-redistribution-actualize/modal-redistribution-actualize.component';
import {StatusLabelComponent} from '@app/status-label/status-label.component';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';
import {filter, forkJoin, merge, Subscription, take, tap} from 'rxjs';
import {
  ModalRedistributionEditComponent
} from '@app/modal/modal-redistribution/modal-redistribution-edit/modal-redistribution-edit.component';
import {BookingStatusLabelComponent} from '@app/booking-status-label/booking-status-label.component';
import {BookingStatusEnum} from '@app/enums/booking-status.enum';
import {
  ModalBookingCancelComponent
} from '@app/modal/modal-booking/modal-booking-cancel/modal-booking-cancel.component';
import {DateLocalPipe} from '@app/pipes/date-local.pipe';


@Component({
  selector: 'app-redistribution-details',
  templateUrl: './redistribution-details.component.html',
  styleUrls: ['./redistribution-details.component.scss'],
  standalone: true,
  imports: [
    ModalBookingCreateComponent,
    DateStringPipe,
    CommonModule,
    ErrorModule,
    MedPaginationModule,
    ModalBookingEditComponent,
    ModalRedistributionCancelComponent,
    ModalRedistributionActualizeComponent,
    StatusLabelComponent,
    ModalRedistributionEditComponent,
    BookingStatusLabelComponent,
    ModalBookingCancelComponent,
    DateLocalPipe
  ],
})
export class RedistributionDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('modalRedistributionCancel', {static: true})
  modalRedistributionCancel!: ModalRedistributionCancelComponent;
  @ViewChild('modalRedistributionEdit', {static: true})
  modalRedistributionEdit!: ModalRedistributionEditComponent;
  @ViewChild('modalRedistributionActualize', {static: true})
  modalRedistributionActualize!: ModalRedistributionActualizeComponent;

  @ViewChild('modalBookingCreate', {static: true})
  modalBookingCreate!: ModalBookingCreateComponent;
  @ViewChild('modalBookingEdit', {static: true})
  modalBookingEdit!: ModalBookingEditComponent;
  @ViewChild('modalBookingCancel', {static: true})
  modalBookingCancel!: ModalBookingCancelComponent;
  isRedistributionOwner = false;
  protected redistributionDetails: any = {};
  protected redistributionOffers: any = [];
  protected offersErrorCode: number = 0;
  protected isParentOrganisation: boolean = false;
  protected userOrganizations: Array<string> = [];
  protected userChildOrganizationsObjects: Array<any> = [];
  protected allOrganizations: Array<any> = [];
  protected activeOffer: any;
  protected pagination = {
    page: 1,
    perPage: 6,
    totalElements: 0,
    totalPages: 0,
  };
  protected readonly OfferStatusEnum = OfferStatusEnum;
  protected userOnlyOrganisationIsPropositionSource = false;
  private modalSubscriptions?: Subscription;
  private modalRedistributionEditSubscription?: Subscription;
  private modalRedistributionActualizeSubscription?: Subscription;
  private modalBookingEditSubscription?: Subscription;


  constructor(
    private router: Router,
    private redistributionDetailsService: RedistributionDetailsService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    forkJoin([this.dataService.userOrganizationsValue.pipe(
      filter((value) => !!value.length),
      take(1),
      tap((organizations) => {
        this.userChildOrganizationsObjects = organizations;
        organizations.forEach((organization) => {
          this.userOrganizations.push(organization['code'].toString());
          this.userOrganizations.push(organization['id'].toString());
        })
      })), this.dataService.allOrganizationsValue.pipe(
      filter(({data}) => !!data.length),
      take(1),
      tap((organizations) => {
          organizations.data.forEach((organization: any) => {

            if (organization.children) {
              organization.children.forEach(({id, fullName}: any) => {
                this.allOrganizations.push({
                  id,
                  fullName,
                });
              });
            }
          })
        }
      ))]).subscribe(() => this.updateData());

    this.modalSubscriptions = merge(
      this.modalRedistributionEdit.onClose,
      this.modalRedistributionActualize.onModalClose,
      this.modalRedistributionCancel.hidden,
      this.modalBookingCreate.hidden,
      this.modalBookingEdit.hidden,
      this.modalBookingCancel.hidden,
    )
      .subscribe(() => this.updateData())

    this.modalRedistributionEditSubscription = this.modalRedistributionEdit.onOpenNext.subscribe((res) => this.modalRedistributionCancel.open(res.id));
    this.modalRedistributionActualizeSubscription = this.modalRedistributionActualize.onOpenNext.subscribe(() => this.showRedistributionEdit())
    this.modalBookingEditSubscription = this.modalBookingEdit.onOpenNext.subscribe((res) => this.showBookingCancel(res));
  }

  ngOnDestroy(): void {
    if (this.modalSubscriptions) {
      this.modalSubscriptions.unsubscribe();
    }

    if (this.modalRedistributionEditSubscription) {
      this.modalRedistributionEditSubscription.unsubscribe();
    }

    if (this.modalRedistributionActualizeSubscription) {
      this.modalRedistributionActualizeSubscription.unsubscribe();
    }

    if (this.modalBookingEditSubscription) {
      this.modalBookingEditSubscription.unsubscribe();
    }
  }

  updateData() {
    this.getRedistributionDetails();
    this.getRedistributionOffers();
  }

  showBookingCancel({id, isOwner}: { id: number, isOwner: boolean }): void {
    this.modalBookingCancel.open({id, isOwner});
  }

  showRedistributionEdit(): void {
    const fields = {
      ...this.redistributionDetails.medicineMedicalDevice,
      offeredAmount: this.redistributionDetails.offeredAmount || 0,
      bookedAmount: this.redistributionDetails.bookedAmount || 0,
      availableAmount: this.redistributionDetails.availableAmount || 0,
      redistributionOfferId: this.redistributionDetails.id,
      status: this.redistributionDetails.offerStatus,
      organizations: this.redistributionDetails.organizations,
    };

    this.modalRedistributionEdit.open(fields);
  }

  showRedistributionActualize(): void {
    this.modalRedistributionActualize.open();
  }

  getRedistributionDetails(): void {
    this.redistributionDetailsService
      .getRedistributionDetails(this.router.url.split('/')[2])
      .pipe(tap(data => {
        this.isParentOrganisation = this.userOrganizations.includes(data.medicineMedicalDevice.organizationId.toString());
      }))
      .subscribe((data) => {
        this.redistributionDetails = data;

        this.isRedistributionOwner = this.userOrganizations.includes(this.redistributionDetails.medicineMedicalDevice.organizationId.toString());

        this.userOnlyOrganisationIsPropositionSource = !(this.userChildOrganizationsObjects
          .filter((org) => org.id !== this.redistributionDetails.medicineMedicalDevice.organizationId).length);
      });
  }

  getRedistributionOffers(): void {
    this.redistributionDetailsService
      .getRedistributionOffers({
        id: this.router.url.split('/')[2],
        pagination: {
          page: this.pagination.page - 1,
          size: this.pagination.perPage,
        },
      })
      .subscribe({
          next: (data) => {
            this.offersErrorCode = 0;
            this.redistributionOffers = [];
            if (!data.content.length) {
              this.offersErrorCode = 204;
              return;
            }
            data.content.forEach((offer: any) => {
              this.redistributionOffers.push({
                ...offer,
                organizationFullName: this.allOrganizations.find(
                  (organization) => organization?.id === offer.organizationId
                )?.fullName,
                modifiedAt: this.parseDateToCorrectFormat(offer.modifiedAt),
              });
            });
            this.pagination.totalPages = data.totalPages;
            this.pagination.totalElements = data.totalElements;
          }, error: (error) => {
            if (error.status === 500) this.offersErrorCode = error.status;
            else this.offersErrorCode = error.status;
          }
        },
      );
  }

  createBooking(): void {
    this.modalBookingCreate.open({
      ...this.redistributionDetails.medicineMedicalDevice,
      offeredAmount: this.redistributionDetails.offeredAmount || 0,
      bookedAmount: this.redistributionDetails.bookedAmount || 0,
      availableAmount: this.redistributionDetails.availableAmount || 0,
      redistributionOfferId: this.redistributionDetails.id,
    });
  }

  onOfferHover(offer: any): void {
    if (!offer || offer.offerStatus === BookingStatusEnum.CANCELLED || this.redistributionDetails?.offerStatus !== OfferStatusEnum.ACTIVE) {
      this.activeOffer = null;
      return;
    }
    this.activeOffer = offer.id;
  }

  onOfferClick(offer: any): void {
    if (!offer || offer.offerStatus === BookingStatusEnum.CANCELLED || this.redistributionDetails?.offerStatus !== OfferStatusEnum.ACTIVE) {
      this.activeOffer = null;
      return;
    }

    this.modalBookingEdit.open({
      ...this.redistributionDetails.medicineMedicalDevice,
      offeredAmount: offer.offeredAmount || 0,
      bookedQuantity: offer.bookedAmount || 0,
      redistributionOfferId: offer.id,
      offer,
    });
  }

  onPageChange(newPage: number): void {
    this.pagination.page = newPage;
    this.getRedistributionOffers();
  }

  parseDateToCorrectFormat(dateString: string): string {
    const [date, time] = dateString.split('T');
    const [year, month, day] = date.split('-');
    const [hours, minutes] = time.split(':');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
}
