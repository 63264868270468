import { OAuthModuleConfig } from "angular-oauth2-oidc";
import { env } from "@environments/environment";

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    sendAccessToken: true,
    allowedUrls: [
        env.signWidgetUrl,
      ],
  },
};
