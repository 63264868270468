import { decomposeUrl } from "@mk/med-localization";
import { AuthConfig } from "angular-oauth2-oidc";

import { env } from "@environments/environment";

const [baseLocale] = decomposeUrl(location.pathname);

export const authConfig: AuthConfig = {
  issuer: env.realmUrl,
  redirectUri: `${location.origin}${location.pathname}`,
  clientId: env.realmClientId,
  responseType: "code",
  strictDiscoveryDocumentValidation: true,
  scope: "openid offline_access profile email",
  requireHttps: false,
  showDebugInformation: true,
  customQueryParams: {
    ui_locales: baseLocale || env.activeLocale,
  },
};
