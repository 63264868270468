import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {ModalContainerComponent} from '@app/modal/modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {FormatISODatePipe} from '@app/pipes/format-iso-date.pipe';
import {TextEllipsisPipe} from '@app/pipes/text-ellipsis.pipe';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {ModalBookingEditService} from './modal-booking-edit.service';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from '@app/dropdown/dropdown.component';
import {ModalBookingCancelComponent} from '../modal-booking-cancel/modal-booking-cancel.component';
import {StatusLabelComponent} from '@app/status-label/status-label.component';
import {BookingStatusLabelComponent} from '@app/booking-status-label/booking-status-label.component';
import {BookingStatusEnum} from '@app/enums/booking-status.enum';

interface Fields {
  offer: any;
  id: number;
  directionName: string | undefined;
  tradeName: string | undefined;
  dosage: string | undefined;
  series: string | undefined;
  dueDate: string | undefined;
  availableQuantity: number | undefined;
  sponsoredBy: string | undefined;
  quarantineQuantity: number | undefined;
  mnnName: string | undefined;
  treatmentProgram: string | undefined;
  redistributionOfferId: number | undefined;
  year: number | undefined;
  redistributionOffer: number | undefined;
}

@Component({
  standalone: true,
  selector: 'app-modal-booking-edit',
  templateUrl: './modal-booking-edit.component.html',
  styleUrls: ['./modal-booking-edit.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    TextEllipsisPipe,
    FormatISODatePipe,
    LoadingAnimationComponent,
    DropdownComponent,
    ModalBookingCancelComponent,
    StatusLabelComponent,
    BookingStatusLabelComponent,
  ],
})
export class ModalBookingEditComponent {
  @Input() fields!: Fields | null;
  @Input() isRedistributionOwner: boolean = false;
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  @Output() onOpenNext = new EventEmitter<{ id: number, isOwner: boolean }>();
  @Output() hidden = new EventEmitter<void>();
  @Output() visible = new EventEmitter<void>();
  protected inputErrorText: string = '';
  protected loadingStatus: boolean = false;
  protected readonly BookingStatusEnum = BookingStatusEnum;

  constructor(
    private toastService: ToastService,
    private modalBookingEditService: ModalBookingEditService
  ) {
  }

  open(fields: Fields) {
    this.visible.emit();
    this.fields = fields;
    this.modalContainer.openModal();
  }

  save() {
    if (this.fields?.redistributionOfferId) {
      this.loadingStatus = true;
      this.modalBookingEditService
        .confirmBookingOffer(this.fields.offer.id)
        .subscribe(
          () => {
            this.loadingStatus = false;
            this.modalContainer.close();
            this.hidden.emit();
            this.toastService.showSuccess('Запис успішно оновлено');
          },
          (error) => {
            this.loadingStatus = false;
            this.modalContainer.close();
            if (error.status >= 500) {
              this.toastService.showError(
                'Сервер не доступний.\nСпробуйте пізніше, або зверніться до адміністратора.'
              );
            } else {
              this.toastService.showError();
            }
          }
        );
    }
  }

  cancel() {
    this.modalContainer.close();
    this.onOpenNext.emit({id: this.fields?.offer.id, isOwner: this.isRedistributionOwner});
  }
}
