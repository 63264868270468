<div *ngIf="!listErrorCode" class="list-container">
  <div *ngFor="let group of listData; let parentId = index" class="group">
    <div *ngIf="checkGroup(group)" class="group-header" [id]="parentId">
      <span
        class="left-part"
        (click)="toggleParent($event, parentId.toString())"
      >
        <med-icon-chevron-down
          *ngIf="openLevel.parent !== parentId.toString()"
          size="12"
        /><med-icon-chevron-up
          *ngIf="openLevel.parent === parentId.toString()"
          size="12"
        />{{ group["fullName"] }}
      </span>
      <span class="right-part" *ngIf="openLevel.parent === parentId.toString()">
        <div
          [class]="{
            'search-container': true
          }"
        >
          <input
            #inputRef
            type="text"
            placeholder="Код ЄДРПОУ/РНОКПП або Назва закладу"
            (keydown.enter)="onInput($event)"
          />
          <span>
            <med-icon-search size="16" />
          </span>
        </div>
      </span>
    </div>
    <div *ngIf="openLevel.parent === parentId.toString()" class="sub-group">
      <ng-container
        *ngFor="let item of getGroupToRender(group); let childId = index"
      >
        <div class="sub-group-container">
          <div
            class="sub-group-header"
            (click)="
              toggleChild($event, parentId.toString() + childId.toString())
            "
          >
            <med-icon-chevron-down
              *ngIf="openLevel.child !== parentId + '' + childId"
              size="12"
            /><med-icon-chevron-up
              *ngIf="openLevel.child === parentId + '' + childId"
              size="12"
            />{{ item["fullName"] }}
          </div>
          <div
            *ngIf="openLevel.child === parentId + '' + childId"
            class="sub-group-body"
            [id]="parentId + '' + childId"
          >
            <app-table
              [organizationCode]="item['code']"
              [sortKey]="'due-date'"
            />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isSubGroupEmpty">
        <div class="error-container">
          <app-error [errorCode]="404"></app-error>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="listErrorCode && listErrorCode !== 401" class="error-container">
  <app-error [errorCode]="listErrorCode"></app-error>
</div>
