<app-modal-container #modalContainer (onSave)="save()" (onClose)="close()">
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <div class="modal-header">
      <img src="assets/ic-plus.svg" alt="Header Icon" />
      <p>Створити пропозицію на перерозподіл</p>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="right">
          <div class="item">
            <span class="header">МНН</span>
            <span class="text" [title]="fields?.mnnName">{{
              fields?.mnnName
            }}</span>
          </div>
        </div>
        <div class="left">
          <div class="item">
            <span class="header">Напрям</span>
            <span class="text" [title]="fields?.treatmentProgram">{{
              fields?.treatmentProgram
            }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="right">
          <div class="item">
            <span class="header">Торгова назва</span>
            <span class="text" [title]="fields?.tradeName">{{
              fields?.tradeName | textEllipsis
            }}</span>
          </div>
        </div>
        <div class="left">
          <div class="item">
            <span class="header">Дозування</span>
            <span class="text" [title]="fields?.dosage">{{
              fields?.dosage || "-"
            }}</span>
          </div>
          <div class="item">
            <span class="header">Серія/Серійний номер</span>
            <span class="text" [title]="fields?.series">{{
              fields?.series || "-"
            }}</span>
          </div>
          <div class="item">
            <span class="header">Придатний до</span>
            <span class="text" [title]="fields?.dueDate | formatISODate">{{
              fields?.dueDate | formatISODate
            }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="right"></div>
        <div class="left">
          <div class="item">
            <span class="header">Доступно</span>
            <span class="text" [title]="fields?.availableQuantity">{{
              fields?.availableQuantity || "-"
            }}</span>
          </div>
          <div class="item">
            <span class="header">Джерело надходжень</span>
            <span class="text" [title]="fields?.sponsoredBy">{{
              fields?.sponsoredBy || "-"
            }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="right">
          <div class="item">
            <span class="header">Кількість</span>
            <input #inputRef class="input" (input)="onInputChange($event)" />
            <span class="input-error-text">{{ inputErrorText }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="close-btn"
        data-bs-dismiss="modal"
        (click)="close()"
      >
        Скасувати
      </button>
      <button type="button" class="save-btn" (click)="save()">
        Підтвердтити
      </button>
    </div>
  </ng-container>
</app-modal-container>
