import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "root",
})
export class InitializerService {
  constructor(private oauthSvc: OAuthService) {}

  init() {
    return this.oauthSvc.loadDiscoveryDocumentAndLogin();
  }
}
