import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {env} from '@environments/environment';
import {Observable} from 'rxjs';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';

export interface OfferingEditPayload {
  id: number;
  offerStatus: OfferStatusEnum;
  organizations?: number[];
  offeredAmount?: number;
  reasonForCancellation?: string;
}

export const completeProposalValidationServerRejectMessage = 'Operation is not allowed. OfferedAmount must be equal to the total amount specified in all confirmed bookings.';


@Injectable({
  providedIn: 'root',
})
export class ModalRedistributionEditService {
  constructor(private http: HttpClient) {
  }

  editRedistributionOffer(payload: OfferingEditPayload): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}/accounting/v1/redistribution-offer`, {
        ...payload
      }
    );
  }
}
