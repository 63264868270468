import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { Modal } from "bootstrap";

@Component({
  standalone: true,
  selector: "app-modal-container",
  templateUrl: "./modal-container.component.html",
  styleUrls: ["./modal-container.component.scss"],
})
export class ModalContainerComponent implements AfterViewInit {
  @ViewChild("modal", { static: true }) modalRef!: ElementRef;
  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();
  private modal!: Modal;

  constructor() {}

  ngAfterViewInit() {
    this.modal = new Modal(this.modalRef.nativeElement, {});
  }

  close() {
    this.onClose.emit();
    this.modal?.toggle();
  }

  save() {
    this.onSave.emit();
  }

  openModal() {
    this.modal?.show();
  }
}
