import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";

@Component({
  selector: "app-toggle-switch",
  templateUrl: "./toggle-switch.component.html",
  styleUrls: ["./toggle-switch.component.scss"],
})
export class ToggleSwitchComponent implements OnChanges {
  @Input() isDisabled?: boolean = false;
  @Output() onAction = new EventEmitter<{ state: boolean }>();
  isActive = false;

  ngOnChanges(): void {
    if (this.isDisabled) {
      this.isActive = false;
      this.onAction.emit({ state: this.isActive });
    }
  }

  onChange(): void {
    this.onAction.emit({ state: this.isActive });
  }
}
