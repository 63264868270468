import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ModalContainerComponent} from '@app/modal/modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {FormatISODatePipe} from '@app/pipes/format-iso-date.pipe';
import {TextEllipsisPipe} from '@app/pipes/text-ellipsis.pipe';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {ModalBookingCreateService} from './modal-booking-create.service';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from '@app/dropdown/dropdown.component';
import {DataService} from '@app/shared/data.service';
import {BookingStatusEnum} from '@app/enums/booking-status.enum';
import {BookingStatusLabelComponent} from '@app/booking-status-label/booking-status-label.component';

const ERROR_MESSAGES = {
  notANumber: 'Введіть число',
  greaterThanZero: 'Бронювання зазначенної кількості неможливе',
  fieldRequired: 'Бракує даних',
  greaterThanExist: 'Бронювання зазначенної кількості неможливе',
};

interface Fields {
  id: number;
  directionName: string | undefined;
  tradeName: string | undefined;
  dosage: string | undefined;
  series: string | undefined;
  dueDate: string | undefined;
  sponsoredBy: string | undefined;
  quarantineQuantity: number | undefined;
  mnnName: string | undefined;
  treatmentProgram: string | undefined;
  redistributionOfferId: number | undefined;
  year: number | undefined;
  redistributionOffer: number | undefined;
  organizationId: number | undefined;
  availableAmount: number | undefined;
}

@Component({
  standalone: true,
  selector: 'app-modal-booking-create',
  templateUrl: './modal-booking-create.component.html',
  styleUrls: ['./modal-booking-create.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    TextEllipsisPipe,
    FormatISODatePipe,
    LoadingAnimationComponent,
    DropdownComponent,
    BookingStatusLabelComponent,
  ],
})
export class ModalBookingCreateComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @Input() fields!: Fields | null;
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  @ViewChild('inputRef') inputRef!: ElementRef;
  @Output() hidden = new EventEmitter<void>();
  @Output() visible = new EventEmitter<void>();
  protected inputErrorText: string = '';
  protected dropdownErrorText: string = '';
  protected loadingStatus: boolean = false;
  protected selectedOrganizationId: number = -1;
  protected organizationsDropdownlist: any[] = [];
  protected dropdownError: boolean = false;
  protected readonly BookingStatusEnum = BookingStatusEnum;
  private inputSubscription!: Subscription;
  private inputValue: string = '';
  private userOrganizations: any[] = [];

  constructor(
    private toastService: ToastService,
    private modalBookingCreateService: ModalBookingCreateService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.dataService.userOrganizationsValue.subscribe((nextValue) => {
      this.userOrganizations = nextValue;
    });
  }

  ngAfterViewInit() {
    this.inputSubscription = fromEvent(this.inputRef.nativeElement, 'input')
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((event: unknown) => this.onInputChange(event as Event));
  }

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }

  onInputChange(event: Event) {
    this.inputValue = (event.target as HTMLInputElement).value;

    if (isNaN(Number(this.inputValue))) {
      this.inputErrorText = ERROR_MESSAGES.notANumber;
      this.inputRef.nativeElement.classList.add('error');
    } else if (this.inputValue.length && Number(this.inputValue) <= 0) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanZero;
      this.inputRef.nativeElement.classList.add('error');
    } else if (Number(this.inputValue) > Number(this.fields?.availableAmount)) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      this.inputRef.nativeElement.classList.add('error');
      return;
    } else {
      this.inputErrorText = '';
      this.inputRef.nativeElement.classList.remove('error');
    }
  }

  onItemSelected(item: string) {
    this.dropdownErrorText = '';
    this.dropdownError = false;
    this.selectedOrganizationId = this.userOrganizations.find(
      (organization) => organization.fullName === item
    )?.id;
  }

  resetForm() {
    this.inputValue = '';
    this.inputErrorText = '';
    this.inputRef.nativeElement.classList.remove('error');
    this.inputRef.nativeElement.value = '';
    this.dropdownErrorText = '';
    this.dropdownError = false;
    this.selectedOrganizationId = -1;
  }

  open(fields: Fields) {
    this.visible.emit();
    this.fields = fields;
    this.modalContainer.openModal();
    this.resetForm();
    this.organizationsDropdownlist = this.userOrganizations
      .filter((organization) => organization.id !== fields.organizationId)
      .map((item) => item.fullName);
  }

  save() {
    if (this.inputErrorText || this.dropdownErrorText.length) return;
    if (!this.inputValue.length) {
      this.inputErrorText = ERROR_MESSAGES.fieldRequired;
      this.inputRef.nativeElement.classList.add('error');
      return;
    }
    if (this.selectedOrganizationId < 0) {
      this.dropdownErrorText = ERROR_MESSAGES.fieldRequired;
      this.dropdownError = true;
      return;
    }
    if (Number(this.inputValue) > Number(this.fields?.availableAmount)) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      this.inputRef.nativeElement.classList.add('error');
      return;
    }
    if (this.fields?.redistributionOfferId) {
      this.loadingStatus = true;
      this.modalBookingCreateService
        .createBookingOffer({
          organizationId: this.selectedOrganizationId,
          redistributionOfferId: this.fields.redistributionOfferId,
          bookedAmount: parseInt(this.inputValue),
        })
        .subscribe(
          () => {
            this.loadingStatus = false;
            this.modalContainer.close();
            this.hidden.emit();
            this.toastService.showSuccess('Запис успішно створено');
          },
          (error) => {
            this.loadingStatus = false;
            this.modalContainer.close();
            if (error.status >= 500) {
              this.toastService.showError(
                'Сервер не доступний.\nСпробуйте пізніше, або зверніться до адміністратора.'
              );
            } else {
              this.toastService.showError();
            }
          }
        );
    }
  }
}
