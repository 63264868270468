import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {ModalContainerComponent} from '@app/modal/modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {
  ModalRedistributionActualizeService
} from '@app/modal/modal-redistribution/modal-redistribution-actualize/modal-redistribution-actualize.service';

@Component({
  standalone: true,
  selector: 'app-modal-redistribution-actualize',
  templateUrl: './modal-redistribution-actualize.component.html',
  styleUrls: ['./modal-redistribution-actualize.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    LoadingAnimationComponent,
    FormsModule,
  ],
})
export class ModalRedistributionActualizeComponent {
  @Input() offerId: number = 0;
  @Output() onModalClose = new EventEmitter<void>();
  @Output() onOpenNext = new EventEmitter<void>();
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  protected loadingStatus: boolean = false;

  constructor(
    private modalRedistributionActualizeService: ModalRedistributionActualizeService,
    private toastService: ToastService,
  ) {
  }

  open() {
    this.modalContainer.openModal();
  }

  confirmActualization() {
    this.loadingStatus = true;
    this.modalRedistributionActualizeService.actualizeRedistribution(this.offerId)
      .subscribe({
        next: () => {
          this.toastService.showSuccess('Запис успішно оновлено');
          this.onModalClose.emit();
          this.loadingStatus = false;
          this.modalContainer.close();

        },
        error: () => {
          this.onOpenNext.emit();
          this.loadingStatus = false;
          this.modalContainer.close();
        }
      });

  }
}
