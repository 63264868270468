import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MedIconChevronLeftModule,
  MedIconChevronRightModule,
} from "@mk/med-icons";

import { MedPaginationComponent } from "./med-pagination.component";

@NgModule({
  declarations: [MedPaginationComponent],
  imports: [CommonModule, MedIconChevronLeftModule, MedIconChevronRightModule],
  exports: [MedPaginationComponent],
})
export class MedPaginationModule {}
