import {AfterViewInit, Component, Input, OnInit, ViewChild,} from '@angular/core';
import {RequestFilterKeys, TableService} from './table.service';
import {tableHeader} from '@app/shared/table.constants';
import {DoubleFilterKeys, FilterKeys} from '@app/filter/filter.service';
import {
  ModalRedistributionCreateComponent
} from '@app/modal/modal-redistribution/modal-redistribution-create/modal-redistribution-create.component';
import {DataService} from '@app/shared/data.service';
import {ContextMenuComponent} from '@app/context-menu/context-menu.component';
import {filter} from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() organizationCode?: string = '';
  @Input() sortKey?: string = '';
  @ViewChild('modalRedistribution')
  modalRedistribution!: ModalRedistributionCreateComponent;
  @ViewChild('contextMenu') contextMenu!: ContextMenuComponent;
  tableHeader: { label: string; key: string }[] = tableHeader;
  tableErrorCode: number = 0;
  tableBody: { [key: string]: any }[] = [];
  tableBodyErrorCode: number = 0;
  pagination = {
    page: 1,
    perPage: 20,
    totalElements: 0,
    totalPages: 0,
  };
  sort: { [key: string]: 'ASC' | 'DESC' } = {};
  defaultFilters: { [key: string]: any } = {};
  selectedFilters: { [key: string]: any } = {};
  filterKeys: any = FilterKeys;
  requestFilterKeys: any = RequestFilterKeys;
  resetFilters: boolean = false;
  filterCount: number = 0;
  doubleFilterKeys: any = DoubleFilterKeys;
  activeRow: any;
  hoveredRow: any;
  isModalRedistributionVisible: boolean = false;
  userOrganizations: Array<string> = [];
  isExpired: boolean = false;
  isShownOnlyMyOrganisations: boolean = false;
  userOrganizationCodes: Array<string> = [];

  constructor(
    private tableService: TableService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.defaultFilters = {
      ...(this.organizationCode && {
        organizationCode: [this.organizationCode],
      }),
    };

    this.dataService.userOrganizationsValue.subscribe((organizations) => {
      organizations.forEach((organization) => {
        this.userOrganizations.push(organization['code'].toString());
        this.userOrganizations.push(organization['id'].toString());
        this.userOrganizationCodes.push(organization['code'].toString());
      });

      this.getBodyData({
        pageable: {
          page: 0,
          size: this.pagination.perPage,
        },
        filter: {...this.defaultFilters, ...this.selectedFilters},
      });
    });


    this.dataService.isExpiredValue
      .pipe(filter((isExpired) => this.isExpired !== isExpired))
      .subscribe((isExpired) => {
        if (isExpired) this.selectedFilters['isExpired'] = isExpired;
        else delete this.selectedFilters['isExpired'];
        this.isExpired = isExpired;
        this.getBodyData({
          pageable: {
            page: 0,
            size: this.pagination.perPage,
          },
          filter: {...this.defaultFilters, ...this.selectedFilters},
        });
      });

    this.dataService.showMyOrganizationValue
      .pipe(filter((showMyOrganizations) => this.isShownOnlyMyOrganisations !== showMyOrganizations))
      .subscribe((showMyOrganizations) => {
        this.isShownOnlyMyOrganisations = showMyOrganizations;
        this.filterCount = Object.keys(this.selectedFilters).length + Number(this.isShownOnlyMyOrganisations);

        this.getBodyData({
          pageable: {
            page: 0,
            size: this.pagination.perPage,
          },
          filter: {...this.defaultFilters, ...this.selectedFilters},
        });
      })
  }

  ngAfterViewInit() {
    this.contextMenu.hidden.subscribe(() => {
      if (
        (this.activeRow || this.hoveredRow) &&
        !this.isModalRedistributionVisible
      ) {
        this.activeRow = null;
        this.hoveredRow = null;
      }
    });

    this.modalRedistribution.visible.subscribe(() => {
      this.isModalRedistributionVisible = true;
    });

    this.modalRedistribution.hidden.subscribe(() => {
      if (this.activeRow || this.hoveredRow) {
        this.isModalRedistributionVisible = false;
        this.activeRow = null;
        this.hoveredRow = null;
      }
    });
  }

  getBodyData(payload?: any): void {
    if (this.isShownOnlyMyOrganisations && !this.organizationCode) {
      payload.organizationCode = this.userOrganizationCodes;
    }
    this.tableService.getBodyData(payload).subscribe(
      (bodyData) => {
        this.tableBody = bodyData.content;
        this.pagination.totalPages = bodyData.totalPages;
        this.pagination.totalElements = bodyData.totalElements;
        if (this.tableBody.length === 0) this.tableBodyErrorCode = 404;
        else this.tableBodyErrorCode = 0;
      },
      (error) => {
        if (error.status === 500) this.tableErrorCode = error.status;
        else this.tableBodyErrorCode = error.status;
        console.error('Error fetching table body data', error);
      }
    );
  }

  getPreselectedOption(key: string): { [key: string]: any } {
    switch (key) {
      case 'organizationCode':
        return {[this.filterKeys[key]]: this.organizationCode};
      default:
        return {};
    }
  }

  getCellTitle(value: string): string {
    return value && value.length > 76 ? value : '';
  }

  isColumnFilterable(columnKey: string): boolean {
    return Object.keys(this.filterKeys).includes(columnKey);
  }

  onFilterChange({key, payload}: any): void {
    if (
      !payload ||
      ((Array.isArray(payload) || typeof payload === 'string') &&
        !payload.length) ||
      (this.doubleFilterKeys.includes(key) && !payload.from && !payload.to)
    ) {
      delete this.selectedFilters[this.requestFilterKeys[key]];
      this.filterCount = Object.keys(this.selectedFilters).length;
      this.getBodyData({
        pageable: {
          page: 0,
          size: this.pagination.perPage,
        },
        filter: {...this.defaultFilters, ...this.selectedFilters},
      });
      return;
    }
    this.selectedFilters[this.requestFilterKeys[key]] = payload;
    this.filterCount = Object.keys(this.selectedFilters).length + Number(this.isShownOnlyMyOrganisations);
    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: {...this.defaultFilters, ...this.selectedFilters},
    });
  }

  onSortChange(payload: { [key: string]: 'ASC' | 'DESC' }): void {
    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: {...this.defaultFilters, ...this.selectedFilters},
      sort: {...this.sort, ...payload},
    });
    this.sort = {...this.sort, ...payload};
  }

  onResetFilters(): void {
    this.resetFilters = true;
    setTimeout(() => {
      this.resetFilters = false;
    }, 0);
    this.dataService.showMyOrganization = false;
    this.isShownOnlyMyOrganisations = false;
    this.selectedFilters = {};
    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: this.defaultFilters,
    });
  }

  onFilterError(error: any): void {
    console.error(error.text, error.payload);
    this.tableErrorCode = error.payload.status;
  }

  onPageChange(newPage: number): void {
    this.getBodyData({
      filter: {...this.defaultFilters, ...this.selectedFilters},
      pageable: {
        page: newPage - 1,
        size: this.pagination.perPage,
      },
    });
  }

  onRowClick(event: MouseEvent, row: any): void {
    if (this.userOrganizations.includes(row.organizationId.toString())) {
      this.activeRow = row;
      this.contextMenu.show({
        coordinates: {x: event.clientX, y: event.clientY},
        options: [
          {
            label: 'Запропонувати на перерозподіл',
            action: () => this.modalRedistribution.open(row),
            isAvailable: this.activeRow.availableQuantity > 0,
          },
        ],
      });
    }
  }

  onRowHover(row: any): void {
    if (this.userOrganizations.includes(row.organizationId.toString()))
      this.hoveredRow = row;
  }

  onTableScroll(): void {
    if (this.contextMenu) {
      this.contextMenu.hide();
    }
  }
}
