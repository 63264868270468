<div class="checkbox-list-container">
  <ng-container *ngIf="listToRender.length">
    <app-checkbox
      class="select-all"
      [checked]="allChecked"
      [label]="'Обрати всі з переліку'"
      (checkedChange)="onSelectToggleAll()"
    />
    <span class="separator"></span>
    <div class="checkbox-list">
      <div class="checkbox-item" *ngFor="let option of listToRender">
        <app-checkbox
          [label]="option"
          [checked]="checkedList[option]"
          (checkedChange)="onCheckedChange($event)"
        />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!listToRender.length">
    <p class="no-data-error">Нічого не знайдено.</p>
  </ng-container>
  <span class="separator"></span>
  <button type="button" class="btn reset-button" (click)="onDeselectAll()">
    Скинути все
  </button>
</div>
