<div class="toggle-container">
  <label class="switch">
    <input
      (change)="onShowMyOrganization()"
      [disabled]="isDisabled ? 'disabled' : false"
      [ngModel]="isActive"
      type="checkbox"
    />
    <span class="slider round"></span>
  </label>
  <span class="toggle-label"> Показувати лише мої організації </span>
</div>
