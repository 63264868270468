import {Component, Input} from '@angular/core';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-status-label',
    templateUrl: './status-label.component.html',
    styleUrls: ['./status-label.component.scss'],
    standalone: true,
    imports: [
        CommonModule
    ]
})
export class StatusLabelComponent {
    @Input() status!: OfferStatusEnum | null;

    statusMapping = {
        [OfferStatusEnum.DRAFT]: 'Чернетка',
        [OfferStatusEnum.ACTIVE]: 'Актуально',
        [OfferStatusEnum.EXPIRED]: 'Протерміновано',
        [OfferStatusEnum.COMPLETE]: 'Виконано',
        [OfferStatusEnum.CANCELLED]: 'Скасовано',
    }
}
