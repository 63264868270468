<div *ngIf="error[errorCode]" class="error-container">
  <div class="error-header">
    <img [src]="error[errorCode].imageSrc" alt="Error Icon" />
    <span>{{ customError?.title || error[errorCode].title }}</span>
  </div>
  <div class="error-content">
    <span>{{ customError?.description || error[errorCode].description }}</span>
  </div>
</div>

<div *ngIf="!error[errorCode]" class="error-container">
  <div class="error-header">
    <img src="assets/500-status-logo.png" alt="Error Icon" />
    <span>Непідтримувана помилка.</span>
  </div>
  <div class="error-content">
    <span
      >Виникла помилка, яку не підтримує наша система.<br />Будь ласка,
      зверніться до служби підтримки для отримання додаткової допомоги.</span
    >
  </div>
</div>
