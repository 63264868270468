import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatISODate",
  pure: true,
  standalone: true,
})
export class FormatISODatePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return "-";

    const isoDatePattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
    if (isoDatePattern.test(value)) {
      const [year, month] = value.split("T")[0].split("-");
      return `${month}.${year}`;
    }

    return value;
  }
}
