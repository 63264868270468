<div *ngIf="!tableErrorCode" class="table-container">
  <div (scroll)="onTableScroll()" class="table-wrapper">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th
          *ngFor="let header of tableHeader"
          [class]="{
              'tooltip-cell':
                header.key === 'redistributionOffer'
            }"
        >
          <div class="th-content">
            <p *ngIf="!isColumnFilterable(header.key)">{{ header.label }}</p>
            <app-tooltip
              *ngIf="header.key === 'redistributionOffer'"
              class="custom-tooltip"
              tooltip="Згідно постанови №298, пункт 15, перерозподіл одержаних
              товарів"
            />
            <app-filter
              (onError)="onFilterError($event)"
              (selectedOptionsChange)="onFilterChange($event)"
              (sortChange)="onSortChange($event)"
              *ngIf="isColumnFilterable(header.key)"
              [key]="filterKeys[header.key]"
              [preselectedOption]="getPreselectedOption(header.key)"
              [reset]="resetFilters"
              [sortKey]="sortKey"
            >{{ header.label }}
            </app-filter
            >
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="!tableBodyErrorCode">
        <tr
          (click)="onRowClick($event, row)"
          (mouseout)="hoveredRow = null"
          (mouseover)="onRowHover(row)"
          *ngFor="let row of tableBody"
          [class.active]="activeRow === row"
          [class.hover]="hoveredRow === row"
        >
          <td
            *ngFor="let cell of tableHeader"
            [title]="getCellTitle(row[cell.key])"
          >
            {{ row[cell.key] | dateString | textEllipsis }}
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="tableBodyErrorCode && tableBodyErrorCode !== 401">
        <tr>
          <td [attr.colspan]="13">
            <div class="error-cell-content">
              <app-error [errorCode]="tableBodyErrorCode"></app-error>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="table-footer">
    <div class="left">
      <strong>Застосовані фільтри</strong> <span>{{ filterCount }}</span>
      <div (click)="onResetFilters()" class="icon-container">
        <med-icon-reset class="icon" size="16"></med-icon-reset>
      </div>
    </div>
    <div class="right">
      <med-pagination
        (pageChange)="onPageChange($event)"
        [(page)]="pagination.page"
        [perPage]="pagination.perPage"
        [total]="pagination.totalElements"
      ></med-pagination>
    </div>
  </div>
</div>
<div *ngIf="tableErrorCode && tableErrorCode !== 401" class="error-container">
  <app-error [errorCode]="tableErrorCode"></app-error>
</div>
<app-modal-redistribution-create #modalRedistribution/>
<app-context-menu #contextMenu/>
