import {AfterViewInit, Component, OnInit, ViewChild,} from '@angular/core';
import {RedistributionsTableService, RequestFilterKeys,} from './redistributions-table.service';
import {tableHeader} from '@app/shared/redistributions-table.constants';
import {ReddistributionFilterKeys} from '@app/filter/filter.service';
import {CommonModule} from '@angular/common';
import {MedIconResetModule} from '@mk/med-icons';
import {MedPaginationModule} from '@med-ui/med-pagination/med-pagination.module';
import {FilterModule} from '@app/filter/filter.module';
import {TooltipModule} from '@app/tooltip/tooltip.module';
import {ErrorModule} from '@app/error/error.module';
import {ModalBookingCreateComponent} from '../modal/modal-booking/modal-booking-create/modal-booking-create.component';
import {
  ModalRedistributionEditComponent
} from '../modal/modal-redistribution/modal-redistribution-edit/modal-redistribution-edit.component';
import {TextEllipsisPipe} from '@app/pipes/text-ellipsis.pipe';
import {DateStringPipe} from '@app/pipes/date-string.pipe';
import {ContextMenuComponent} from '@app/context-menu/context-menu.component';
import {DataService} from '@app/shared/data.service';
import {Router} from '@angular/router';
import {StatusLabelComponent} from '@app/status-label/status-label.component';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';
import {
  ModalRedistributionCancelComponent
} from '@app/modal/modal-redistribution/modal-redistribution-cancel/modal-redistribution-cancel.component';

@Component({
  selector: 'app-redistributions-table',
  templateUrl: './redistributions-table.component.html',
  styleUrls: ['./redistributions-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MedIconResetModule,
    MedPaginationModule,
    FilterModule,
    TooltipModule,
    ErrorModule,
    ModalBookingCreateComponent,
    ModalRedistributionEditComponent,
    TextEllipsisPipe,
    DateStringPipe,
    ContextMenuComponent,
    StatusLabelComponent,
    ModalRedistributionCancelComponent,
  ],
})
export class RedistributionsTableComponent implements OnInit, AfterViewInit {
  @ViewChild('modalBookingCreate', {static: true})
  modalBookingCreate!: ModalBookingCreateComponent;
  @ViewChild('modalRedistributionEdit', {static: true})
  modalRedistributionEdit!: ModalRedistributionEditComponent;
  @ViewChild('modalRedistributionCancel', {static: true})
  modalRedistributionCancel!: ModalRedistributionCancelComponent;
  @ViewChild('contextMenu') contextMenu!: ContextMenuComponent;
  tableHeader: { label: string; key: string }[] = tableHeader;
  tableErrorCode: number = 0;
  tableBody: { [key: string]: any }[] = [];
  tableBodyErrorCode: number = 0;
  pagination = {
    page: 1,
    perPage: 20,
    totalElements: 0,
    totalPages: 0,
  };
  sort: { [key: string]: 'ASC' | 'DESC' } = {};
  defaultFilters: { [key: string]: any } = {};
  selectedFilters: { [key: string]: any } = {};
  filterKeys: any = ReddistributionFilterKeys;
  requestFilterKeys: any = RequestFilterKeys;
  resetFilters: boolean = false;
  filterCount: number = 0;
  activeRow: any;
  isModalBookingCreateVisible: boolean = false;
  userOrganizations: Array<string> = [];
  userChildOrganizationsObjects: Array<any> = [];
  showMyOrganization: boolean = false;
  hoveredRow: any;

  constructor(
    private redistributionsTableService: RedistributionsTableService,
    private dataService: DataService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getBodyData({filter: this.defaultFilters});

    this.dataService.showMyOrganizationValue.subscribe((state) => {
      if (state === this.showMyOrganization) return;
      this.showMyOrganization = state;
      this.filterCount = Object.keys(this.selectedFilters).length + Number(this.showMyOrganization);
      const organizationCode = state ? this.userOrganizations : null;
      if (organizationCode) {
        this.defaultFilters = {
          ...this.defaultFilters,
          organizationCode,
        };
      } else {
        delete this.defaultFilters['organizationCode'];
      }
      this.getBodyData({
        filter: {
          ...this.defaultFilters,
          ...this.selectedFilters,
        },
      });
    });

    this.dataService.userOrganizationsValue.subscribe((organizations) => {
      organizations.forEach((organization) => {
        this.userOrganizations.push(organization['code'].toString());
        this.userOrganizations.push(organization['id'].toString());
      });
      this.userChildOrganizationsObjects = organizations;
    });

    this.modalRedistributionEdit.onOpenNext.subscribe((res) => this.modalRedistributionCancel.open(res.id));
    this.modalRedistributionEdit.onClose.subscribe(() => this.updateData());

    this.modalRedistributionCancel.hidden.subscribe(() => this.updateData());
    this.modalBookingCreate.hidden.subscribe(() => this.updateData());
  }

  ngAfterViewInit() {
    this.contextMenu.hidden.subscribe(() => {
      if (
        (this.activeRow || this.hoveredRow) &&
        !this.isModalBookingCreateVisible
      ) {
        this.activeRow = null;
        this.hoveredRow = null;
      }
    });
    this.modalBookingCreate.visible.subscribe(() => {
      this.isModalBookingCreateVisible = true;
    });
    this.modalBookingCreate.hidden.subscribe(() => {
      if (this.activeRow || this.hoveredRow) {
        this.isModalBookingCreateVisible = false;
        this.activeRow = null;
        this.hoveredRow = null;
      }

      this.updateData();
    });
  }

  updateData() {
    this.getBodyData({
      pageable: {
        page: this.pagination.page - 1,
        size: this.pagination.perPage,
      },
      filter: {...this.defaultFilters, ...this.selectedFilters},
    });
  }

  getBodyData(payload?: any): void {
    this.redistributionsTableService.getBodyData(payload).subscribe({
        next: (bodyData) => {
          this.tableBody = bodyData.content.map((row: any) => {
            return {
              ...row.medicineMedicalDevice,
              offeredAmount: row.offeredAmount || 0,
              bookedAmount: row.bookedAmount || 0,
              availableAmount: row.availableAmount || 0,
              redistributionOfferId: row.id,
              status: row.offerStatus,
              organizations: row.organizations,
            };
          });
          this.pagination.totalPages = bodyData.totalPages;
          this.pagination.totalElements = bodyData.totalElements;
          if (this.tableBody.length === 0) this.tableBodyErrorCode = 404;
          else this.tableBodyErrorCode = 0;
        },
        error: (error) => {
          if (error.status === 500) this.tableErrorCode = error.status;
          else this.tableBodyErrorCode = error.status;
        }
      }
    );
  }

  getCellTitle(value: string): string {
    return value && value.length > 76 ? value : '';
  }

  isColumnFilterable(columnKey: string): boolean {
    return Object.keys(this.filterKeys).includes(columnKey);
  }

  onFilterChange({key, payload}: any): void {
    if (!payload || !payload.length) {
      delete this.selectedFilters[this.requestFilterKeys[key]];
      this.filterCount = Object.keys(this.selectedFilters).length;
      this.getBodyData({
        pageable: {
          page: 0,
          size: this.pagination.perPage,
        },
        filter: {...this.defaultFilters, ...this.selectedFilters},
      });
      return;
    }
    this.selectedFilters[this.requestFilterKeys[key]] = payload;
    this.filterCount = Object.keys(this.selectedFilters).length + Number(this.showMyOrganization);
    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: {...this.defaultFilters, ...this.selectedFilters},
    });
  }

  onResetFilters(): void {
    this.resetFilters = true;
    setTimeout(() => {
      this.resetFilters = false;
    }, 0);
    this.selectedFilters = {};

    this.showMyOrganization = false;
    this.dataService.showMyOrganization = false;

    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: this.defaultFilters,
    });
  }

  onFilterError(error: any): void {
    console.error(error.text, error.payload);
    this.tableErrorCode = error.payload.status;
  }

  onPageChange(newPage: number): void {
    this.getBodyData({
      filter: {...this.defaultFilters, ...this.selectedFilters},
      pageable: {
        page: newPage - 1,
        size: this.pagination.perPage,
      },
    });
  }

  trackRow(_: number, row: any) {
    return row.redistributionOfferId;
  }

  onRowClick(event: MouseEvent, row: any): void {
    event.stopImmediatePropagation();
    const isOwner = this.userOrganizations.includes(
      row.organizationId.toString()
    );

    const hasOrganisationsExceptProposition = !!(this.userChildOrganizationsObjects
      .filter((org) => org.id !== row.organizationId).length);

    this.activeRow = row;
    this.contextMenu.show({
      coordinates: {x: event.clientX, y: event.clientY},
      options: [
        {
          label: row.availableAmount > 0
            ? 'Забронювати'
            : 'Забронювати (Все заброньовано)',
          action: () => this.modalBookingCreate.open(row),
          isAvailable: row.availableAmount > 0 && [OfferStatusEnum.ACTIVE].includes(row.status) && hasOrganisationsExceptProposition,
        },
        {
          label: 'Переглянути пропозицію',
          action: () =>
            this.router.navigate([
              '/redistributions',
              row.redistributionOfferId,
            ]),
          isAvailable: true,
        },
        {
          label: 'Оновити пропозицію',
          action: () => this.modalRedistributionEdit.open(row),
          isAvailable: isOwner && [OfferStatusEnum.DRAFT, OfferStatusEnum.ACTIVE].includes(row.status),
        }
      ]
    });
  }

  onRowHover(row: any): void {
    this.hoveredRow = row;
  }

  onTableScroll(): void {
    if (this.contextMenu) {
      this.contextMenu.hide();
    }
  }
}
