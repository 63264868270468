import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {env} from '@environments/environment';
import {Observable} from 'rxjs';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';

@Injectable({
  providedIn: 'root',
})
export class ModalRedistributionActualizeService {
  constructor(private http: HttpClient) {
  }

  actualizeRedistribution(id: number): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}/accounting/v1/redistribution-offer`, {
        id,
        offerStatus: OfferStatusEnum.ACTIVE
      }
    );
  }
}
