import { Component } from "@angular/core";

@Component({
  selector: "app-sign-demo",
  templateUrl: "./sign-demo.component.html",
  styleUrls: ["./sign-demo.component.scss"],
})
export class SignDemoComponent {
  inputText: string = "";
  textToSign: string = "";
  resultText: boolean | null = null;

  signText() {
    this.textToSign = this.inputText;
  }

  receiveResult(result: boolean) {
    this.resultText = result;
  }
}
