<div class="container">
  <input [(ngModel)]="inputText" placeholder="Enter text" />
  <button (click)="signText()">Sign Text</button>
  <div *ngIf="textToSign" class="text-container">
    <p>Text to process: {{ textToSign }}</p>
  </div>
  <app-sign-modal
    class="sign-modal"
    [text]="textToSign"
    (result)="receiveResult($event)"
  ></app-sign-modal>
  <div *ngIf="resultText" class="text-container">
    <p>
      Initial hash and hash from sign:
      <span *ngIf="resultText !== null">{{
        resultText ? "Equal" : "Not Equal"
      }}</span>
    </p>
  </div>
</div>
