import { Component } from "@angular/core";

import { Toast } from "ngx-toastr";

@Component({
  selector: "[toast-component]",
  styleUrls: ["./toast.component.scss"],
  templateUrl: "./toast.component.html",
})
export class ToastComponent extends Toast {}
