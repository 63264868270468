import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MedIconFilterModule, MedIconSearchModule } from "@mk/med-icons";
import { FormsModule } from "@angular/forms";

import { FilterComponent } from "./filter.component";
import { CheckboxListModule } from "../checkbox-list/checkbox-list.module";
import { InputModule } from "../input/input.module";
import { ColumnSortModule } from "@app/column-sort/column-sort.module";
@NgModule({
  declarations: [FilterComponent],
  imports: [
    CommonModule,
    MedIconFilterModule,
    MedIconSearchModule,
    CheckboxListModule,
    FormsModule,
    InputModule,
    ColumnSortModule,
  ],
  exports: [FilterComponent],
})
export class FilterModule {}
