<div class="requisition__modal">
  <div *ngIf="hashedText">
    <med-sign [data]="hashedText" (sign)="onSign($event)"></med-sign>
    <div class="text-container">
      <p>Hashed text: {{ hashedText }}</p>
      <p>Signed text: {{ signedText }}</p>
      <p>Verified object: {{ verifiedText }}</p>
    </div>
  </div>
</div>
