import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { env } from "@environments/environment";

export type FilterInitDataKeys =
  | "treatment-program"
  | "sponsor"
  | "institution-tax"
  | "nomenclature"
  | "due-date"
  | "balance-quantity"
  | "type"
  | "";

export const SortKeys: { [key: string]: string } = {
  "due-date": "dueDate",
  nomenclature: "mnnName",
  "balance-quantity": "balanceQuantity",
};
export const DoubleFilterKeys = ["due-date", "balance-quantity"];
export const FilterKeys = {
  treatmentProgram: "treatment-program",
  sponsoredBy: "sponsor",
  organizationCode: "institution-tax",
  mnnName: "nomenclature",
  dueDate: "due-date",
  balanceQuantity: "balance-quantity",
  type: "type",
};
export const ReddistributionFilterKeys = {
  mnnName: "nomenclature",
};

@Injectable({
  providedIn: "root",
})
export class FilterService {
  private coreUrl = "http://localhost:3000";

  constructor(private http: HttpClient) {}

  getInitialFilterData(key: FilterInitDataKeys): Observable<any> | undefined {
    if (!key.length) return;
    if (key === "type") {
      return this.http.get<any>(
        env.apiUrl + "/accounting/v1/nomenclature/type"
      );
    }
    return this.http.get<any>(
      env.apiUrl + "/accounting/v1/medicines-medical-devices/filter/" + key
    );
  }
}
