export const tableHeader = [
  {key: 'mnnName', label: 'МНН'},
  {key: 'dosage', label: 'Дозування'},
  {key: 'dueDate', label: 'Придатний до'},
  {key: 'tradeName', label: 'Торгова назва'},
  {key: 'status', label: 'Статус'},
  {key: 'treatmentProgram', label: 'Напрям'},
  {key: 'series', label: 'Серія/Каталожний\nномер'},
  {key: 'minUnit', label: 'Мін. облікова\nодиниця'},
  {key: 'offeredAmount', label: 'Запропоновано\nна перерозподіл'},
  {key: 'availableAmount', label: 'Доступно\nдля бронювання'},
  {key: 'institutionName', label: 'Назва закладу'},
];
