import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-column-sort",
  templateUrl: "./column-sort.component.html",
  styleUrls: ["./column-sort.component.scss"],
})
export class ColumnSortComponent {
  @Input() key!: string;
  @Input() bottomBorder = false;
  @Output() onAction = new EventEmitter<{ [key: string]: "ASC" | "DESC" }>();

  sortListDESC(): void {
    this.onAction.emit({ [this.key]: "DESC" });
  }
  sortListASC(): void {
    this.onAction.emit({ [this.key]: "ASC" });
  }
}
