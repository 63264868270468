import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input() items: { link: string; title: string; subTitle: string }[] = [];
  title: string = '';

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getCurrentItem(this.activatedRoute.snapshot);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map(route => route.snapshot)
      )
      .subscribe((snapshot) => this.getCurrentItem(snapshot));
  }

  getCurrentItem(snapshot: ActivatedRouteSnapshot): void {
    this.title = this.items.find(({link}) => link === snapshot.routeConfig?.path)?.title || ''
  }
}
