<div [class]="{ 'column-sort-container': true, 'bottom-border': bottomBorder }">
  <p class="sort text asc" (click)="sortListASC()">
    <img src="assets/icons/a-z-down.svg" alt="icon" width="16" height="16" />
    {{
      key === "dueDate"
        ? "Сортування від Меншого до Більшого"
        : "Сортування від А до Я"
    }}
  </p>
  <p class="sort text desc" (click)="sortListDESC()">
    <img src="assets/icons/a-z-up.svg" alt="icon" width="16" height="16" />
    {{
      key === "dueDate"
        ? "Сортування від Більшого до Меншого"
        : "Сортування від Я до А"
    }}
  </p>
  <span *ngIf="bottomBorder" class="separator"></span>
</div>
