import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'textEllipsis',
    pure: true,
    standalone: true
})
export class TextEllipsisPipe implements PipeTransform {
    transform(value: string | undefined, ellipsisLimit = 76): string {
        if(!value) {
            return '-';
        }

        return value.length > ellipsisLimit ? `${value.slice(0, ellipsisLimit)}...` : value;
    }
}