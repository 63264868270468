import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { env } from "@environments/environment";

export type RequestFiltersPayload = {
  [key: string]: any;
  filter: {
    nomenclatureId?: number[];
    organizationCode?: string[];
  };
  pageable: {
    page: number;
    size: number;
  };
};

export const RequestFilterKeys: { [key: string]: string } = {
  nomenclature: "nomenclatureId",
};

@Injectable({
  providedIn: "root",
})
export class RedistributionsTableService {
  constructor(private http: HttpClient) {}

  private defaultRequestFiltersPayload: RequestFiltersPayload = {
    filter: {},
    pageable: {
      page: 0,
      size: 20,
    },
  };

  getBodyData(
    payload: Partial<RequestFiltersPayload> = this.defaultRequestFiltersPayload
  ): Observable<any> {
    const { filter, pageable } = payload;
    const fullPayload = {
      ...this.defaultRequestFiltersPayload.filter,
      ...filter,
    };
    const { nomenclatureId, organizationCode } = fullPayload;
    return this.http.get<any>(
      env.apiUrl +
        "/accounting/v1/redistribution-offer" +
        `?size=${
          pageable?.size || this.defaultRequestFiltersPayload.pageable.size
        }&page=${
          pageable?.page || this.defaultRequestFiltersPayload.pageable.page
        }${
          nomenclatureId?.length
            ? nomenclatureId.map((id) => "&nomenclatureId=" + id).join("")
            : ""
        }${
          organizationCode?.length
            ? organizationCode.map((id) => "&organizationCode=" + id).join("")
            : ""
        }`
    );
  }
}
