import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from "angular-oauth2-oidc";

import { authConfig } from "./auth.config";
import { authModuleConfig } from "./auth-module.config";

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error("AuthModule is already loaded. Import it in the AppModule only");
    }
  }

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: (): OAuthStorage => localStorage },
      ],
    };
  }
}
