import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { env } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalBookingEditService {
  constructor(private http: HttpClient) {}

  confirmBookingOffer(offerId: number): Observable<any> {
    return this.http.post<any>(
      env.apiUrl + "/accounting/v1/booked-offer/" + offerId + "/confirm",
      {}
    );
  }

  cancelBookingOffer(
    offerId: number,
    reasonForCancellation: string
  ): Observable<any> {
    return this.http.post<any>(
      env.apiUrl + "/accounting/v1/booked-offer/" + offerId + "/cancel",
      { reasonForCancellation }
    );
  }
}
