import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
} from "@angular/core";

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent implements OnInit, OnDestroy {
  @Input() type: "single-line" | "multiple-lines" = "single-line";

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private isShowTooltip = false;

  private mouseEnterListener: () => void = () => {};
  private mouseLeaveListener: () => void = () => {};
  private clickListener: () => void = () => {};

  private mouseEnterAction(dropdownToggle: any) {
    this.isShowTooltip = true;
    this.renderer.setProperty(dropdownToggle, "aria-expanded", "true");
    dropdownToggle.click();
  }

  private mouseLeaveAction(dropdownToggle: any) {
    this.isShowTooltip = false;
    this.renderer.setProperty(dropdownToggle, "aria-expanded", "false");
    dropdownToggle.click();
  }

  ngOnInit(): void {
    const dropdown = this.el.nativeElement.querySelector(".dropdown-center");
    const dropdownToggle = dropdown.querySelector(
      '[data-bs-toggle="dropdown"]'
    );

    this.mouseEnterListener = this.renderer.listen(
      dropdown,
      "mouseenter",
      this.mouseEnterAction.bind(this, dropdownToggle)
    );

    this.mouseLeaveListener = this.renderer.listen(
      dropdown,
      "mouseleave",
      this.mouseLeaveAction.bind(this, dropdownToggle)
    );

    this.clickListener = this.renderer.listen(dropdown, "click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (this.isShowTooltip) {
        this.mouseLeaveAction(dropdownToggle);
      } else {
        this.mouseEnterAction(dropdownToggle);
      }
    });
  }

  ngOnDestroy(): void {
    // Clean up listeners
    if (this.mouseEnterListener) {
      this.mouseEnterListener();
    }
    if (this.mouseLeaveListener) {
      this.mouseLeaveListener();
    }
    if (this.clickListener) {
      this.clickListener();
    }
  }
}
