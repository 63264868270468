<div class="app-container">
  <app-header class="header"></app-header>
  <!--TODO: Revert after ZOZ feature approve-->
  <div *ngIf="!userRole.isZOZUser" class="main">
    <!-- <div class="main"> -->
    <app-sidebar [items]="navItems" class="sidebar"></app-sidebar>
    <div class="content-container">
      <div class="content-header">
        <app-breadcrumbs
          [items]="navItems"
          class="breadcrumbs"
        ></app-breadcrumbs>
        <div [class.two-items]="showRedistributions" class="title-container">
          <app-page-title [items]="navItems" class="page-title"/>
          <ng-container *ngIf="showRegistry">
            <app-toggle-switch
              class="toggle-switch"
            />
            <app-state-switch (onAction)="onAppContentChange($event)"/>
          </ng-container>
          <ng-container *ngIf="showRedistributions">
            <app-toggle-switch
              [isDisabled]="showTable"
            />
          </ng-container>
        </div>
      </div>
      <div class="content">
        <ng-container *ngIf="showRegistry">
          <router-outlet *ngIf="showTable" name="table"></router-outlet>
          <router-outlet *ngIf="!showTable" name="list"></router-outlet>
        </ng-container>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
