import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ModalContainerComponent} from '../../modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {FormatISODatePipe} from '@app/pipes/format-iso-date.pipe';
import {TextEllipsisPipe} from '@app/pipes/text-ellipsis.pipe';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {
  completeProposalValidationServerRejectMessage,
  ModalRedistributionEditService,
  OfferingEditPayload
} from './modal-redistribution-edit.service';
import {CommonModule} from '@angular/common';
import {Dropdown} from 'bootstrap';
import {DropdownComponent} from '@app/dropdown/dropdown.component';
import {DataService} from '@app/shared/data.service';
import {StatusLabelComponent} from '@app/status-label/status-label.component';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';

const ERROR_MESSAGES = {
  notANumber: 'Введіть число',
  greaterThanZero: 'Введені дані по номенклатурі мають бути більшими 0',
  fieldRequired: 'Бракує даних',
  greaterThanExist: 'Пропозиція не має перевищувати залишок',
  quarantine: 'Карантин. Пропозиція неможлива',
};

interface Fields {
  redistributionOfferId: number;
  directionName: string | undefined;
  tradeName: string | undefined;
  dosage: string | undefined;
  series: string | undefined;
  dueDate: string | undefined;
  offeredAmount: number | undefined;
  availableAmount: number | undefined;
  sponsoredBy: string | undefined;
  quarantineQuantity: number | undefined;
  mnnName: string | undefined;
  treatmentProgram: string | undefined;
  year: number | undefined;
  organizationId: number | undefined;
  status: OfferStatusEnum;
  organizations: number[];
  bookedAmount: number | undefined;
  availableQuantity: number | undefined;
}

const statusMap = {
  [OfferStatusEnum.DRAFT]: [
    OfferStatusEnum.DRAFT,
    OfferStatusEnum.ACTIVE,
    OfferStatusEnum.CANCELLED,
  ],
  [OfferStatusEnum.ACTIVE]: [
    OfferStatusEnum.ACTIVE,
    OfferStatusEnum.CANCELLED,
    OfferStatusEnum.COMPLETE,
  ]
}

@Component({
  standalone: true,
  selector: 'app-modal-redistribution-edit',
  templateUrl: './modal-redistribution-edit.component.html',
  styleUrls: ['./modal-redistribution-edit.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    TextEllipsisPipe,
    FormatISODatePipe,
    LoadingAnimationComponent,
    DropdownComponent,
    StatusLabelComponent,
  ],
})
export class ModalRedistributionEditComponent
  implements OnInit, AfterViewInit, OnDestroy {
  fields?: Fields;
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  @ViewChild('inputRef') inputRef!: ElementRef;
  @ViewChild('dropdown') dropdownRef!: ElementRef;
  @Output() hidden = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onOpenNext = new EventEmitter<OfferingEditPayload>();
  protected inputErrorText: string = '';
  protected loadingStatus: boolean = false;
  protected dropdownError: boolean = false;
  protected organizationsDropdownlist: string[] = [];
  protected dropdownErrorText: string = '';
  protected selectedOrganizationId: number[] = [];
  protected userOrganizations: any[] = [];
  protected readonly OfferStatusEnum = OfferStatusEnum;
  protected selectedStatus = OfferStatusEnum.DRAFT;
  protected dropdownItems: OfferStatusEnum[] = [];
  protected defaultSelectedOrgs: string[] = [];
  protected isOpenDropdown: boolean = false;
  private inputSubscription!: Subscription;
  private inputValue: string = '';
  private dropdown!: Dropdown;


  constructor(
    private toastService: ToastService,
    private modalRedistributionEditService: ModalRedistributionEditService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.dataService.userOrganizationsValue.subscribe((nextValue) => {
      this.userOrganizations = nextValue;
    });
  }

  ngAfterViewInit() {
    this.inputSubscription = fromEvent(this.inputRef.nativeElement, 'input')
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((event: unknown) => this.onInputChange(event as Event));

    this.resetForm();
  }

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }

  dropdownOpen(): void {
    const dropdownElement = this.dropdownRef.nativeElement;
    if (dropdownElement.classList.contains('show')) {
      this.isOpenDropdown = false;
      this.dropdown.hide();
    } else {
      this.isOpenDropdown = true;
      this.dropdown.show();
      document.querySelectorAll('.dropdown-toggle.show')?.forEach(() => {
        document.querySelector('body')?.click();
      });
      this.dropdown.show();
    }
  }

  onDropdownSelected(item: string) {
    const selectedItems = item.split('&');
    this.dropdownErrorText = '';
    this.dropdownError = false;
    this.selectedOrganizationId = [];
    this.userOrganizations.forEach((organization) => {
      selectedItems.forEach((selectedItem) => {
        if (organization.fullName === selectedItem) {
          this.selectedOrganizationId.push(organization.id);
        }
      });
    });
  }

  onStatusSelected(item: OfferStatusEnum): void {
    this.selectedStatus = item;
  }

  onInputChange(event: Event) {
    this.inputValue = (event.target as HTMLInputElement).value;

    if (isNaN(Number(this.inputValue))) {
      this.inputErrorText = ERROR_MESSAGES.notANumber;
      this.inputRef.nativeElement.classList.add('error');
    } else if (this.inputValue.length && Number(this.inputValue) <= 0) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanZero;
      this.inputRef.nativeElement.classList.add('error');
    } else if (Number(this.inputValue) < Number(this.fields?.bookedAmount)) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      this.inputRef.nativeElement.classList.add('error');
    } else if (Number(this.inputValue) > Number(this.fields?.availableQuantity)) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      this.inputRef.nativeElement.classList.add('error');
    } else {
      this.inputErrorText = '';
      this.inputRef.nativeElement.classList.remove('error');
    }
  }

  resetForm() {
    this.inputValue = '';
    this.inputErrorText = '';
    this.inputRef.nativeElement.classList.remove('error');
    this.inputRef.nativeElement.value = '';
    this.dropdownErrorText = '';
    this.dropdownError = false;
    this.selectedStatus = OfferStatusEnum.DRAFT;
    this.dropdown = new Dropdown(this.dropdownRef.nativeElement, {
      popperConfig: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      },
    });
  }

  open(fields: Fields) {
    this.resetForm();
    this.fields = fields;
    this.organizationsDropdownlist = this.userOrganizations
      // Should be able to create and notify in case only ONE organisation for user exists.filter((organization) => organization.id !== fields?.organizationId)
      .map((item) => item.fullName);

    this.modalContainer.openModal();


    this.selectedStatus = this.fields.status;
    this.inputValue = fields.offeredAmount?.toString() || '';
    this.inputRef.nativeElement.value = fields.offeredAmount?.toString() || '';

    const orgs: string[] = []
    this.userOrganizations.forEach((organization) => {
      fields.organizations.forEach((selectedItem) => {
        if (organization.id === selectedItem) {
          orgs.push(organization.fullName);
        }
      });
    });

    this.defaultSelectedOrgs = orgs;
    // @ts-ignore
    this.dropdownItems = statusMap[this.fields.status];
  }

  save() {
    if (this.selectedStatus === OfferStatusEnum.CANCELLED) {
      this.onOpenNext.emit({
        id: this.fields!.redistributionOfferId,
        offeredAmount: parseInt(this.inputValue),
        offerStatus: this.selectedStatus,
        organizations: this.selectedOrganizationId,
      });
      this.modalContainer.close();
    } else {
      if (this.inputErrorText.length) return;
      if (!this.inputValue.length) {
        this.inputErrorText = ERROR_MESSAGES.fieldRequired;
        this.inputRef.nativeElement.classList.add('error');
        return;
      }
      if (!this.selectedOrganizationId.length && this.selectedStatus !== OfferStatusEnum.DRAFT) {
        this.dropdownErrorText = ERROR_MESSAGES.fieldRequired;
        this.dropdownError = true;
        return;
      }

      if (Number(this.inputValue) <= 0) {
        this.inputErrorText = ERROR_MESSAGES.greaterThanZero;
        this.inputRef.nativeElement.classList.add('error');
        return;
      }

      if (Number(this.inputValue) > Number(this.fields?.availableQuantity) || Number(this.inputValue) < Number(this.fields?.bookedAmount)) {
        if (this.fields?.quarantineQuantity) {
          this.inputErrorText = ERROR_MESSAGES.quarantine;
        } else {
          this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
        }
        this.inputRef.nativeElement.classList.add('error');
        return;
      }

      if (this.fields?.redistributionOfferId) {
        this.loadingStatus = true;

        this.modalRedistributionEditService
          .editRedistributionOffer({
            id: this.fields.redistributionOfferId,
            offeredAmount: parseInt(this.inputValue),
            offerStatus: this.selectedStatus,
            organizations: this.selectedOrganizationId,
          })
          .subscribe({
              next: () => {
                this.loadingStatus = false;
                this.modalContainer.close();
                this.toastService.showSuccess('Запис успішно оновлено');
                this.onClose.emit();
              }, error: (error) => {
                this.loadingStatus = false;
                this.modalContainer.close();
                if (error.status >= 500) {
                  this.toastService.showError(
                    'Сервер не доступний.\nСпробуйте пізніше, або зверніться до адміністратора.'
                  );
                } else if (error.status === 400 && error.error?.messages?.includes(completeProposalValidationServerRejectMessage)) {
                  this.toastService.showError('Має збігатися з сумою всіх підтверджених бронювань');
                } else {
                  this.toastService.showError();
                }
              }
            }
          );
      }
    }
  }
}
  