<div class="dropdown dropdown-center" [class.error]="isError">
  <div
    class="dropdown-toggle"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    #dropdown
    (click)="dropdownOpen($event)"
  >
    <div class="content">
      <span *ngIf="!dropdownButtonText.length" class="default-text">{{
        dropdownButtonDefaultText
      }}</span>
      <span *ngIf="dropdownButtonText.length">{{ dropdownButtonText }} </span>
      <span *ngIf="checkedCounter > 0" class="counter"
        >+{{ checkedCounter }}</span
      >
      <img src="assets/icons/chevron-down.svg" alt="chevron-down" />
    </div>
  </div>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <div class="dropdown-header-container">
      <div class="input-container">
        <input
          #searchInput
          type="text"
          class="form-control"
          placeholder="Пошук"
          [(ngModel)]="searchText"
          (input)="onSearchInputChange($event)"
        />
        <img src="assets/icons/search.svg" alt="search" />
      </div>
      <app-checkbox
        *ngIf="items.length > 0 && multipleSelect"
        class="checkbox"
        [label]="'Обрати всі заклади з переліку'"
        [checked]="allChecked"
        (checkedChange)="onCheckAll()"
      ></app-checkbox>
    </div>
    <div class="items-container">
      <ng-container *ngIf="multipleSelect">
        <div *ngFor="let item of items">
          <app-checkbox
            [label]="item"
            [checked]="checkedList[item]"
            (checkedChange)="onCheckedChange($event)"
          ></app-checkbox>
        </div>
      </ng-container>
      <ng-container *ngIf="!multipleSelect">
        <p *ngFor="let item of items" (click)="onItemSelected(item)">
          {{ item }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
