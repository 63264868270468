<app-modal-container
    #modalContainer
    [width]="536"
>
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation class="loading"></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <img alt="Header Icon" src="assets/ic-success.svg"/>
    <p class="header">
      Ви впевнені, що бажаєте актуалізувати пропозицію на перерозподіл?
    </p>
    <p class="description">

      Актуалізувавши пропозицію ви надішлете сповіщення закладам охорони
      здоровʼя і пропозиція стане доступна для бронювання.
    </p>

    <div class="modal-footer">
      <button
        class="close-btn"
        data-bs-dismiss="modal"
        type="button"
      >
        <span>Відмінити</span>
      </button>
      <button (click)="confirmActualization()" class="save-btn" type="button">
        <span>Підтвердити</span>
      </button>
    </div>
  </ng-container>
</app-modal-container>
