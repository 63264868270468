<app-modal-container
    #modalContainer
    (onClose)="close()"
    (onSave)="cancel()"
    [width]="536"
>
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation class="loading"></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <div class="modal-header">
      <img alt="Header Icon" src="assets/ic-comments.svg"/>
      <p>{{ isOwner ? 'Відхилити' : 'Скасувати' }}</p>
    </div>
    <div class="modal-body">
      <div class="row inputs-row">
        <div class="right">
          <div class="item">
            <span class="header">{{ isOwner ? 'Причина відхилення' : 'Причина скасування' }}</span>
            <textarea
              (input)="inputErrorText = ''"
              [(ngModel)]="cancelReason"
              [class.error]="inputErrorText.length"
              [placeholder]="isOwner ? 'Вкажіть причину відхилення' : 'Вкажіть причину скасування'"
              class="text"
            ></textarea>
            <span *ngIf="inputErrorText.length" class="error-text">{{
                    inputErrorText
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="close-btn"
        data-bs-dismiss="modal"
        type="button"
      >
        <span>Відмінити</span>
      </button>
      <button (click)="cancel()" class="save-btn" type="button">
        <span>Підтвердити</span>
      </button>
    </div>
  </ng-container>
</app-modal-container>
