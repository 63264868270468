import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {env} from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedistributionDetailsService {
  constructor(private http: HttpClient) {
  }

  getRedistributionDetails(id: string): Observable<any> {
    return this.http.get<any>(
      env.apiUrl + '/accounting/v1/redistribution-offer/' + id
    );
  }

  getRedistributionOffers({
                            id,
                            pagination,
                          }: {
    id: string;
    pagination: {
      page: number;
      size: number;
    };
  }): Observable<any> {
    return this.http.get<any>(
      env.apiUrl +
      '/accounting/v1/redistribution-offer/' +
      id +
      '/booked-offer?' +
      'page=' +
      pagination.page +
      '&size=' +
      pagination.size
    );
  }
}
