import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-checkbox-list",
  templateUrl: "./checkbox-list.component.html",
  styleUrls: ["./checkbox-list.component.scss"],
})
export class CheckboxListComponent implements OnChanges {
  @Input() isReset?: boolean = false;
  @Input() listToRender: string[] = [];
  allChecked: boolean = false;
  checkedList: { [key: string]: boolean } = {};
  @Output() checkedListChange = new EventEmitter<Array<string>>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isReset) {
      this.onDeselectAll();
    }
    if (
      changes["listToRender"] &&
      changes["listToRender"].currentValue.length
    ) {
      this.allChecked =
        Object.values(this.checkedList).filter((option) => option).length ===
        changes["listToRender"].currentValue.length;
    }
  }

  emitCheckedList(): void {
    this.checkedListChange.emit(
      Object.keys(this.checkedList).filter((key) => this.checkedList[key])
    );
  }

  onCheckedChange(checkedUpdate: { [key: string]: boolean }): void {
    this.checkedList = { ...this.checkedList, ...checkedUpdate };
    this.allChecked =
      Object.values(this.checkedList).filter((option) => option).length ===
      this.listToRender.length;
    this.emitCheckedList();
  }

  onSelectAll(): void {
    this.allChecked = true;
    this.checkedList = this.listToRender.reduce(
      (acc: { [key: string]: boolean }, item) => {
        acc[item] = true;
        return acc;
      },
      {}
    );
    this.emitCheckedList();
  }

  onSelectToggleAll(): void {
    if (this.allChecked) {
      this.onDeselectAll();
    } else {
      this.onSelectAll();
    }
  }

  onDeselectAll(): void {
    this.allChecked = false;
    this.checkedList = {};
    this.emitCheckedList();
  }

  sortListASC(): void {
    this.listToRender = this.listToRender.sort();
  }

  sortListDESC(): void {
    this.listToRender = this.listToRender.sort().reverse();
  }
}
