import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ColumnSortComponent } from "./column-sort.component";

@NgModule({
  declarations: [ColumnSortComponent],
  imports: [CommonModule],
  exports: [ColumnSortComponent],
})
export class ColumnSortModule {}
