import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";

type Options = {
  label: string;
  action: () => void;
  isAvailable: boolean;
};

type Payload = {
  coordinates: { x: number; y: number };
  options: Array<Options>;
};

const MENU_POSITION_OFFSET = 10;

@Component({
  selector: "app-context-menu",
  templateUrl: "./context-menu.component.html",
  styleUrls: ["./context-menu.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class ContextMenuComponent {
  @ViewChild("menu", { static: true }) menu!: ElementRef;
  @Output() hidden = new EventEmitter<void>();
  private visible = false;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  protected options!: Array<Options>;

  show({ coordinates: { x, y }, options }: Payload) {
    if (this.visible) {
      this.hide();
      return;
    }
    if (!options || !options.length) return;
    this.options = options;
    this.visible = true;
    this.menu.nativeElement.style.display = "block";

    const { top, left } = this.getTopLeft(x, y);
    this.menu.nativeElement.style.top = `${top}px`;
    this.menu.nativeElement.style.left = `${left}px`;

    this.onMouseLeave();
  }

  hide() {
    this.clearTimeout();
    this.visible = false;
    this.menu.nativeElement.style.display = "none";
    this.hidden.emit();
  }

  protected optionActionWrapper({ action, isAvailable }: Options) {
    if (isAvailable) {
      action();
      this.hide();
    }
  }

  private getTopLeft(x: number, y: number) {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const menuRect = this.menu.nativeElement.getBoundingClientRect();

    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    let top = y + MENU_POSITION_OFFSET + scrollY;
    let left = x + MENU_POSITION_OFFSET + scrollX;

    if (top + menuRect.height > viewportHeight + scrollY) {
      top = viewportHeight + scrollY - menuRect.height - MENU_POSITION_OFFSET;
    }
    if (left + menuRect.width > viewportWidth + scrollX) {
      left = viewportWidth + scrollX - menuRect.width - MENU_POSITION_OFFSET;
    }

    return { top, left };
  }

  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  @HostListener("body:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const isTdElement =
      (event.target as HTMLElement).tagName.toLowerCase() === "td";

    if (this.visible && !isTdElement) {
      this.hide();
    }
  }

  @HostListener("mouseenter")
  private onMouseEnter() {
    this.clearTimeout();
  }

  @HostListener("mouseleave")
  private onMouseLeave() {
    this.timeoutId = setTimeout(() => {
      this.hide();
    }, 5000);
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    this.hide();
  }
}
