import { Component } from "@angular/core";
import { env } from "@environments/environment";
import { authConfig } from "@app/oauth/auth.config";
import { OAuthService } from "angular-oauth2-oidc";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  constructor(
    public oauthSvc: OAuthService,
  ) {}
  eStockHomePage = env.eStockHomePage;

  logout() {
    authConfig.redirectUri = `${location.origin}${location.pathname}`;
    this.oauthSvc.redirectUri = `${location.origin}${location.pathname}`;
    this.oauthSvc.logOut();
  }
}
