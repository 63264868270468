export const createDateFromString = (dateString: string): Date => {
  if (!dateString) return new Date();

  let parts: string[];
  if (dateString.includes(".")) {
    parts = dateString.split(".");
  } else if (dateString.includes("/")) {
    parts = dateString.split("/");
  } else if (dateString.includes("-")) {
    parts = dateString.split("-");
  } else {
    return new Date();
  }

  const [month, year] = parts.map(Number);

  return new Date(year, month - 1, 2);
};

export const createISODateFromString = (dateString: string): string => {
  if (!dateString) return "";

  const date = createDateFromString(dateString);
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
};
