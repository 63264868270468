import { Component, Input, OnInit } from "@angular/core";
import { Router, UrlSegment, UrlTree } from "@angular/router";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() items: { [key: string | number]: string }[] = [];
  title: string = "";

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.getCurrentItem();
    this.router.events.subscribe(() => {
      this.getCurrentItem();
    });
  }

  getCurrentItem(): void {
    let subSegment = false;
    const currentItem = this.items.find((item) => {
      const urlTree: UrlTree = this.router.parseUrl(this.router.url);
      const urlSegments: UrlSegment[] =
        urlTree.root.children["primary"]?.segments || [];
      if (urlSegments.length === 0 && item["link"] === "") return true;
      if (urlSegments.length > 1) subSegment = true;
      return urlSegments.some((segment) => segment.path === item["link"]);
    });
    this.title = currentItem
      ? subSegment
        ? currentItem["subTitle"]
        : currentItem["title"]
      : "";
  }
}
