import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent {
  @Input() label: string = "";
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<{ [key: string]: boolean }>();

  onCheckedChange(): void {
    this.checked = !this.checked;
    this.checkedChange.emit({ [this.label]: this.checked });
  }
}
