<app-modal-container #modalContainer (onSave)="save()" [height]="532">
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <div class="modal-header">
      <img alt="Header Icon" src="assets/ic-plus.svg"/>
      <p>Створити бронювання</p>
    </div>
    <div class="modal-body">
      <div class="details">
        <div class="row">
          <div class="right">
            <div class="item">
              <span class="header">Статус</span>
              <app-booking-status-label [status]="BookingStatusEnum.ACTIVE"/>
            </div>
          </div>
          <div class="left">
            <div class="item">
              <span class="header">Напрям</span>
              <span
                [title]="fields?.year + ', ' + fields?.directionName"
                class="text"
              >{{ fields?.year + ", " + fields?.directionName }}</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="right">
            <div class="item">
              <span class="header">МНН</span>
              <span [title]="fields?.mnnName" class="text multiline-ellipsis">{{
                      fields?.mnnName
                }}</span>
            </div>
          </div>
          <div class="left">
            <div class="item">
              <span class="header">Дозування</span>
              <span [title]="fields?.dosage" class="text multiline-ellipsis clamp-1">{{
                      fields?.dosage || "-"
                }}</span>
            </div>
            <div class="item">
              <span class="header">Серія/Каталожний номер</span>
              <span [title]="fields?.series" class="text">{{
                      fields?.series || "-"
                }}</span>
            </div>
            <div class="item">
              <span class="header">Придатний до</span>
              <span [title]="fields?.dueDate | formatISODate" class="text">{{
                      fields?.dueDate | formatISODate
                }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="right">
            <div class="item">
              <span class="header">Торгова назва</span>
              <span [title]="fields?.tradeName" class="text multiline-ellipsis">{{
                      fields?.tradeName
                }}</span>
            </div>
          </div>
          <div class="left">
            <div class="item">
              <span class="header">Запропоновано на перерозподіл</span>
              <span [title]="fields?.availableAmount" class="text">{{
                      fields?.availableAmount || "-"
                }}</span>
            </div>
            <div class="item">
              <span class="header">Джерело надходжень</span>
              <span [title]="fields?.sponsoredBy" class="text multiline-ellipsis">{{
                      fields?.sponsoredBy || "-"
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row inputs-row">
        <div class="right">
          <div class="item">
            <span class="header">Кількість</span>
            <input
                #inputRef
                (input)="onInputChange($event)"
                class="input"
                placeholder="Зарезервувати"
            />
            <span class="input-error-text">{{ inputErrorText }}</span>
          </div>
        </div>
        <div class="left">
          <div class="item">
            <span class="header">Для закладу охорони здоров'я</span>
            <app-dropdown
              (itemSelected)="onItemSelected($event)"
              [isError]="dropdownError"
              [items]="organizationsDropdownlist"
              [placeholder]="'Заклади які отримують сповіщення'"
            ></app-dropdown>
            <span class="input-error-text">{{ dropdownErrorText }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="close-btn"
        data-bs-dismiss="modal"
        type="button"
      >
        Відмінити
      </button>
      <button (click)="save()" class="save-btn" type="button">Зберегти</button>
    </div>
  </ng-container>
</app-modal-container>
