import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CheckboxListComponent } from "./checkbox-list.component";
import { CheckboxModule } from "../checkbox/checkbox.module";


@NgModule({
  declarations: [CheckboxListComponent],
  imports: [CommonModule, CheckboxModule],
  exports: [CheckboxListComponent],
})
export class CheckboxListModule {}
