<div class="toggle-container">
  <label class="switch">
    <input
      type="checkbox"
      [(ngModel)]="isActive"
      (change)="onChange()"
      [disabled]="isDisabled ? 'disabled' : false"
    />
    <span class="slider round"></span>
  </label>
  <span class="toggle-label"> Показувати лише мої організації </span>
</div>
