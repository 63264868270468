import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { SignModalService } from "./sign-modal.service"; // Adjust the path as necessary
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: "app-sign-modal",
  templateUrl: "./sign-modal.component.html",
  styleUrls: ["./sign-modal.component.scss"],
})
export class SignModalComponent implements OnChanges {
  @Input() text: string = "";
  @Output() result: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected hashedText: string = "";
  protected verifiedText: string = "";
  protected signedText: string = "";

  constructor(private signModalService: SignModalService) {}

  onTextChange() {
    this.hashString();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.text &&
      changes["text"] &&
      changes["text"].currentValue !== changes["text"].previousValue
    ) {
      this.onTextChange();
    }
  }

  onSign(sign: string) {
    this.signedText = sign;
    this.verifyString();
  }

  verifyString() {
    this.signModalService
      .verifyString(this.signedText)
      .pipe(
        map((verifiedObj: any) => {
          this.verifiedText = JSON.stringify(verifiedObj);
          this.result.emit(this.hashedText === verifiedObj.decryptedData);
        }),
        catchError((error: any) => {
          console.error("Error verifying string", error);
          return of(null);
        })
      )
      .subscribe();
  }

  hashString() {
    this.signModalService
      .hashString(this.text)
      .pipe(
        map((hashedString: string) => {
          this.hashedText = hashedString;
        }),
        catchError((error: any) => {
          console.error("Error hashing string", error);
          return of(null);
        })
      )
      .subscribe();
  }
}
