<app-modal-container
    #modalContainer
    (onSave)="save()"
    [height]="500"
>
  <ng-container *ngIf="loadingStatus">
    <app-loading-animation></app-loading-animation>
  </ng-container>
  <ng-container *ngIf="!loadingStatus">
    <div class="modal-header">
      <img alt="Header Icon" src="assets/ic-comments.svg"/>
      <p>Оновити пропозицію на перерозподіл</p>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="left">
          <div class="item">
            <span class="header">МНН</span>
            <span [title]="fields?.mnnName" class="text multiline-ellipsis">{{
                    fields?.mnnName
              }}</span>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span class="header">Напрям</span>
            <span
              [title]="fields?.year + ', ' + fields?.directionName"
              class="text multiline-ellipsis"
            >{{ (fields?.year + ", " + fields?.directionName) }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="left">
          <div class="item">
            <span class="header">Торгова назва</span>
            <span [title]="fields?.tradeName" class="text multiline-ellipsis">{{
                    fields?.tradeName
              }}</span>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span class="header">Дозування</span>
            <span [title]="fields?.dosage" class="text multiline-ellipsis">{{
                    fields?.dosage || "-"
              }}</span>
          </div>
          <div class="item">
            <span class="header">Серія/Каталожний номер</span>
            <span [title]="fields?.series" class="text">{{
                    fields?.series || "-"
              }}</span>
          </div>
          <div class="item">
            <span class="header">Придатний до</span>
            <span [title]="fields?.dueDate | formatISODate" class="text">{{
                    fields?.dueDate | formatISODate
              }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="left"></div>
        <div class="right">
          <div class="item">
            <span class="header">Доступно</span>
            <span [title]="fields?.offeredAmount" class="text">{{
                    fields?.offeredAmount || "-"
              }}</span>
          </div>
          <div class="item">
            <span class="header">Джерело надходжень</span>
            <span [title]="fields?.sponsoredBy" class="text multiline-ellipsis clamp-1">{{
                    fields?.sponsoredBy || "-"
              }}</span>
          </div>
          <div class="item">
            <span class="header">Статус</span>
            <span class="text">
              <div class="dropdown dropdown-center">
                <div
                    #dropdown
                    (click)="dropdownOpen()"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    id="dropdownStatusButton"
                >
                  <app-status-label [status]="selectedStatus"/>
                  <img
                    *ngIf="!isOpenDropdown"
                    alt="Arrow Down"
                    src="assets/icons/chevron-down.svg"
                  />
                  <img
                    *ngIf="isOpenDropdown"
                    alt="Arrow Up"
                    src="assets/icons/chevron-up.svg"
                  />
                </div>
                <ul
                  aria-labelledby="dropdownStatusButton"
                  class="dropdown-menu"
                >
                  <li *ngFor="let item of dropdownItems">
                    <span
                      (click)="onStatusSelected(item)"
                      class="dropdown-item"
                    ><app-status-label [status]="item"
                    /></span>
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="left">
          <div class="item">
            <span class="header">Кількість</span>
            <input
                #inputRef
                (input)="onInputChange($event)"
                class="input"
                placeholder="Запропонувати"
            />
            <span class="input-error-text">{{ inputErrorText }}</span>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span class="header">Сповістити</span>
            <app-dropdown
              (itemSelected)="onDropdownSelected($event)"
              [defaultSelectedValues]="defaultSelectedOrgs"
              [isError]="dropdownError"
              [items]="organizationsDropdownlist"
              [multipleSelect]="true"
              [placeholder]="'Заклади які отримають сповіщення'"
            ></app-dropdown>
            <span class="input-error-text">{{ dropdownErrorText }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="close-btn"
        data-bs-dismiss="modal"
        type="button"
      >
        Відмінити
      </button>
      <button (click)="save()" class="save-btn" type="button">
        Підтвердтити
      </button>
    </div>
  </ng-container>
</app-modal-container>
