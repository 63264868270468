import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
  Output,
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ModalContainerComponent } from "../modal-container/modal-container.component";
import { InputModule } from "@app/input/input.module";
import { ToastService } from "@app/toast/toast.service";
import { FormatISODatePipe } from "@app/pipes/format-iso-date.pipe";
import { TextEllipsisPipe } from "@app/pipes/text-ellipsis.pipe";
import { LoadingAnimationComponent } from "../../loading-animation/loading-animation.component";
import { ModalRedistributionService } from "./modal-redistribution.service";
import { CommonModule } from "@angular/common";

const ERROR_MESSAGES = {
  notANumber: "Введіть число",
  greaterThanZero: "Введені дані по номенклатурі мають бути більшими 0",
  fieldRequired: "Поле обов'язкове до заповнення",
  greaterThanExist: "Пропозиція не має перевищувати залишок",
  quarantine: "Карантин. Пропозиція неможлива",
};

interface Fields {
  id: number;
  directionName: string | undefined;
  tradeName: string | undefined;
  dosage: string | undefined;
  series: string | undefined;
  dueDate: string | undefined;
  availableQuantity: number | undefined;
  sponsoredBy: string | undefined;
  quarantineQuantity: number | undefined;
  mnnName: string | undefined;
  treatmentProgram: string | undefined;
}

@Component({
  standalone: true,
  selector: "app-modal-redistribution",
  templateUrl: "./modal-redistribution.component.html",
  styleUrls: ["./modal-redistribution.component.scss"],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    TextEllipsisPipe,
    FormatISODatePipe,
    LoadingAnimationComponent,
  ],
})
export class ModalRedistributionComponent implements AfterViewInit, OnDestroy {
  @Input() fields!: Fields | null;
  @ViewChild("modalContainer") modalContainer!: ModalContainerComponent;
  @ViewChild("inputRef") inputRef!: ElementRef;
  @Output() hidden = new EventEmitter<void>();
  @Output() visible = new EventEmitter<void>();

  private inputSubscription!: Subscription;
  private inputValue: string = "";

  protected inputErrorText: string = "";
  protected loadingStatus: boolean = false;

  constructor(
    private toastService: ToastService,
    private modalRedistributionService: ModalRedistributionService
  ) {}

  ngAfterViewInit() {
    this.inputSubscription = fromEvent(this.inputRef.nativeElement, "input")
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((event: unknown) => this.onInputChange(event as Event));
  }

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }

  onInputChange(event: Event) {
    this.inputValue = (event.target as HTMLInputElement).value;

    if (isNaN(Number(this.inputValue))) {
      this.inputErrorText = ERROR_MESSAGES.notANumber;
      this.inputRef.nativeElement.classList.add("error");
    } else if (this.inputValue.length && Number(this.inputValue) <= 0) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanZero;
      this.inputRef.nativeElement.classList.add("error");
    } else {
      this.inputErrorText = "";
      this.inputRef.nativeElement.classList.remove("error");
    }
  }

  resetForm() {
    this.inputValue = "";
    this.inputErrorText = "";
    this.inputRef.nativeElement.classList.remove("error");
    this.inputRef.nativeElement.value = "";
  }

  open(fields: Fields) {
    this.visible.emit();
    this.fields = fields;
    this.modalContainer.openModal();
    this.resetForm();
  }

  close() {
    this.hidden.emit();
  }

  save() {
    if (this.inputErrorText.length) return;
    if (!this.inputValue.length) {
      this.inputErrorText = ERROR_MESSAGES.fieldRequired;
      this.inputRef.nativeElement.classList.add("error");
      return;
    }
    if (Number(this.inputValue) > Number(this.fields?.availableQuantity)) {
      if (this.fields?.quarantineQuantity) {
        this.inputErrorText = ERROR_MESSAGES.quarantine;
      } else {
        this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      }
      this.inputRef.nativeElement.classList.add("error");
      return;
    }
    if (this.fields?.id) {
      this.loadingStatus = true;
      this.modalRedistributionService
        .saveRedistributionOffer({
          id: this.fields.id,
          amount: parseInt(this.inputValue),
        })
        .subscribe(
          () => {
            this.loadingStatus = false;
            this.modalContainer.close();
            this.toastService.showSuccess();
          },
          () => {
            this.loadingStatus = false;
            this.modalContainer.close();
            this.toastService.showError();
          }
        );
    }
  }
}
