import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ModalContainerComponent} from '../../modal-container/modal-container.component';
import {InputModule} from '@app/input/input.module';
import {ToastService} from '@app/toast/toast.service';
import {FormatISODatePipe} from '@app/pipes/format-iso-date.pipe';
import {TextEllipsisPipe} from '@app/pipes/text-ellipsis.pipe';
import {LoadingAnimationComponent} from '@app/loading-animation/loading-animation.component';
import {ModalRedistributionCreateService} from './modal-redistribution-create.service';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from '@app/dropdown/dropdown.component';
import {DataService} from '@app/shared/data.service';
import {OfferStatusEnum} from '@app/enums/offer-status.enum';
import {StatusLabelComponent} from '@app/status-label/status-label.component';

const ERROR_MESSAGES = {
  notANumber: 'Введіть число',
  greaterThanZero: 'Введені дані по номенклатурі мають бути більшими 0',
  fieldRequired: 'Бракує даних',
  greaterThanExist: 'Пропозиція не має перевищувати залишок',
  quarantine: 'Карантин. Пропозиція неможлива',
};

interface Fields {
  id: number;
  directionName: string | undefined;
  tradeName: string | undefined;
  dosage: string | undefined;
  series: string | undefined;
  dueDate: string | undefined;
  availableQuantity: number | undefined;
  sponsoredBy: string | undefined;
  quarantineQuantity: number | undefined;
  mnnName: string | undefined;
  treatmentProgram: string | undefined;
  year: number | undefined;
  organizationId: number | undefined;
}

@Component({
  standalone: true,
  selector: 'app-modal-redistribution-create',
  templateUrl: './modal-redistribution-create.component.html',
  styleUrls: ['./modal-redistribution-create.component.scss'],
  imports: [
    CommonModule,
    ModalContainerComponent,
    InputModule,
    TextEllipsisPipe,
    FormatISODatePipe,
    LoadingAnimationComponent,
    DropdownComponent,
    StatusLabelComponent
  ],
})
export class ModalRedistributionCreateComponent
  implements OnInit, AfterViewInit, OnDestroy {
  fields?: Fields;
  @ViewChild('modalContainer') modalContainer!: ModalContainerComponent;
  @ViewChild('inputRef') inputRef!: ElementRef;
  @Output() hidden = new EventEmitter<void>();
  @Output() visible = new EventEmitter<void>();
  protected inputErrorText: string = '';
  protected loadingStatus: boolean = false;
  protected dropdownError: boolean = false;
  protected organizationsDropdownlist: string[] = [];
  protected dropdownErrorText: string = '';
  protected selectedOrganizationId: number[] = [];
  protected userOrganizations: any[] = [];
  protected readonly OfferStatusEnum = OfferStatusEnum;
  private inputSubscription!: Subscription;
  private inputValue: string = '';

  constructor(
    private toastService: ToastService,
    private modalRedistributionCreateService: ModalRedistributionCreateService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.dataService.userOrganizationsValue.subscribe((nextValue) => {
      this.userOrganizations = nextValue;
    });
  }

  ngAfterViewInit() {
    this.inputSubscription = fromEvent(this.inputRef.nativeElement, 'input')
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((event: unknown) => this.onInputChange(event as Event));
  }

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }

  onItemSelected(item: string) {
    const selectedItems = item.split('&');
    this.dropdownErrorText = '';
    this.dropdownError = false;
    this.selectedOrganizationId = [];
    this.userOrganizations.forEach((organization) => {
      selectedItems.forEach((selectedItem) => {
        if (organization.fullName === selectedItem) {
          this.selectedOrganizationId.push(organization.id);
        }
      });
    });
  }

  onInputChange(event: Event) {
    this.inputValue = (event.target as HTMLInputElement).value;

    if (isNaN(Number(this.inputValue))) {
      this.inputErrorText = ERROR_MESSAGES.notANumber;
      this.inputRef.nativeElement.classList.add('error');
    } else if (this.inputValue.length && (Number(this.inputValue) <= 0)) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanZero;
      this.inputRef.nativeElement.classList.add('error');
    } else if (!this.fields?.availableQuantity || Number(this.inputValue) > Number(this.fields.availableQuantity)) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      this.inputRef.nativeElement.classList.add('error');
    } else {
      this.inputErrorText = '';
      this.inputRef.nativeElement.classList.remove('error');
    }
  }

  resetForm() {
    this.inputValue = '';
    this.inputErrorText = '';
    this.inputRef.nativeElement.classList.remove('error');
    this.inputRef.nativeElement.value = '';
    this.dropdownErrorText = '';
    this.selectedOrganizationId = [];
    this.dropdownError = false;
  }

  open(fields: Fields) {
    if (!fields) {
      return;
    }
    this.visible.emit();
    this.fields = fields;
    this.organizationsDropdownlist = this.userOrganizations
      // Should be able to create and notify in case only ONE organisation for user exists .filter((organization) => organization.id !== fields.organizationId)
      .map((item) => item.fullName);
    this.modalContainer.openModal();
    this.resetForm();
  }

  close() {
    this.hidden.emit();
  }

  save() {
    if (this.inputErrorText.length) return;
    if (!this.inputValue.length) {
      this.inputErrorText = ERROR_MESSAGES.fieldRequired;
      this.inputRef.nativeElement.classList.add('error');
      return;
    }

    if (Number(this.inputValue) <= 0) {
      this.inputErrorText = ERROR_MESSAGES.greaterThanZero;
      this.inputRef.nativeElement.classList.add('error');
      return;
    }

    if (Number(this.inputValue) > Number(this.fields?.availableQuantity)) {
      if (this.fields?.quarantineQuantity) {
        this.inputErrorText = ERROR_MESSAGES.quarantine;
      } else {
        this.inputErrorText = ERROR_MESSAGES.greaterThanExist;
      }
      this.inputRef.nativeElement.classList.add('error');
      return;
    }

    if (this.fields?.id) {
      this.loadingStatus = true;

      this.modalRedistributionCreateService
        .creatRedistributionOffer({
          id: this.fields.id,
          amount: parseInt(this.inputValue),
          organizations: this.selectedOrganizationId
        })
        .subscribe({
            next: () => {
              this.loadingStatus = false;
              this.modalContainer.close();
              this.toastService.showSuccess(
                'Чернетка пропозиції успішно створена.\nАктуалізуйте її у Реєстрі пропозицій або\nна сторінці пропозиції, аби бронювання\nпропозиції стало доступним.'
              );
            }, error: (error) => {
              this.loadingStatus = false;
              this.modalContainer.close();
              if (error.status >= 500) {
                this.toastService.showError(
                  'Сервер не доступний.\nСпробуйте пізніше, або зверніться до адміністратора.'
                );
              } else {
                this.toastService.showError();
              }
            }
          }
        );
    }
  }
}
