<div *ngIf="!tableErrorCode" class="table-container">
  <div class="table-wrapper" (scroll)="onTableScroll()">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th
            *ngFor="let header of tableHeader"
            [class]="{
              'tooltip-cell':
                header.key === 'redistributionOffer' ||
                header.key === 'bookedQuantity'
            }"
          >
            <div class="th-content">
              <p *ngIf="!isColumnFilterable(header.key)">{{ header.label }}</p>
              <app-tooltip
                class="custom-tooltip"
                *ngIf="
                  header.key === 'redistributionOffer' ||
                  header.key === 'bookedQuantity'
                "
                >Згідно постанови №298, пункт 15, перерозподіл одержаних
                товарів</app-tooltip
              >
              <app-filter
                *ngIf="isColumnFilterable(header.key)"
                [key]="filterKeys[header.key]"
                [sortKey]="sortKey"
                [reset]="resetFilters"
                (selectedOptionsChange)="onFilterChange($event)"
                (sortChange)="onSortChange($event)"
                (onError)="onFilterError($event)"
                [preselectedOption]="getPreselectedOption(header.key)"
                >{{ header.label }}</app-filter
              >
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!tableBodyErrorCode">
          <tr
            *ngFor="let row of tableBody"
            (click)="onRowClick($event, row)"
            (mouseover)="onRowHover(row)"
            (mouseout)="hoveredRow = null"
            [class.active]="activeRow === row"
            [class.hover]="hoveredRow === row"
          >
            <td
              *ngFor="let cell of tableHeader"
              [title]="getCellTitle(row[cell.key])"
            >
              {{ row[cell.key] | dateString | textEllipsis }}
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="tableBodyErrorCode && tableBodyErrorCode !== 401">
          <tr>
            <td [attr.colspan]="13">
              <div class="error-cell-content">
                <app-error [errorCode]="tableBodyErrorCode"></app-error>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="table-footer">
    <div class="left">
      <strong>Застосовані фільтри</strong> <span>{{ filterCount }}</span>
      <div class="icon-container" (click)="onResetFilters()">
        <med-icon-reset class="icon" size="16"></med-icon-reset>
      </div>
    </div>
    <div class="right">
      <med-pagination
        [perPage]="pagination.perPage"
        [total]="pagination.totalElements"
        [(page)]="pagination.page"
        (pageChange)="onPageChange($event)"
      ></med-pagination>
    </div>
  </div>
</div>
<div *ngIf="tableErrorCode && tableErrorCode !== 401" class="error-container">
  <app-error [errorCode]="tableErrorCode"></app-error>
</div>
<app-modal-redistribution #modalRedistribution />
<app-context-menu #contextMenu />
