<ng-container *ngIf="!doubleInput">
  <div [ngClass]="{ 'search-container': true, error: isError }">
    <input
      #inputRef
      type="text"
      (input)="onInputChange($event)"
      [placeholder]="placeholder"
      [maxLength]="maxSymbols"
      (keydown.enter)="inputAction()"
    />
    <span (click)="inputAction()">
      <med-icon-search size="16" />
    </span>
  </div>
  <p *ngIf="isError" class="error-text">
    {{ errorMessage }}
  </p>
  <ng-content select="[singleInput]"></ng-content>
</ng-container>
<ng-container *ngIf="doubleInput">
  <div [class]="{ double: true, error: isError }">
    <div class="search-container">
      <input
        #minInputRef
        type="text"
        (input)="onMinInputChange($event)"
        [placeholder]="placeholder"
        [maxLength]="maxSymbols"
        (keydown.enter)="inputAction()"
      />
      <span (click)="inputAction()">
        <med-icon-search size="16" />
      </span>
    </div>
    <div class="search-container">
      <input
        #maxInputRef
        type="text"
        (input)="onMaxInputChange($event)"
        [placeholder]="placeholder2.length ? placeholder2 : placeholder"
        [maxLength]="maxSymbols"
        (keydown.enter)="inputAction()"
      />
      <span (click)="inputAction()">
        <med-icon-search size="16" />
      </span>
    </div>
  </div>
  <p *ngIf="isError" class="error-text">
    {{ errorMessage }}
  </p>
  <ng-content select="[doubleInput]"></ng-content>
  <ng-container *ngIf="key === 'due-date'">
    <app-checkbox
      class="six-months-checkbox"
      [checked]="isSixMonthsExpiration"
      label="Збігає термін придатності"
      (checkedChange)="onSelectSixMonthsExpiration($event)"
    />
  </ng-container>
  <ng-container *ngIf="key === 'due-date' || 'balance-quantity'">
    <span class="separator"></span>
    <button type="button" class="btn reset-button" (click)="onDeselectAll()">
      Скинути все
    </button>
  </ng-container>
</ng-container>
