import { Component, Input } from "@angular/core";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent {
  @Input() errorCode: number = 200;

  error: { [key: number]: any } = {
    404: {
      title: "Реєстр не містить записів, які відповідають шуканим значенням.",
      description: "Спробуйте інший запит.",
      imageSrc: "assets/404-status-logo.png",
    },
    500: {
      title: "Сервер не доступний.",
      description: "Спробуйте пізніше, або зверніться до адміністратора.",
      imageSrc: "assets/500-status-logo.png",
    },
    403: {
      title: "В доступі відмовлено.",
      description: "Зверніться до адміністратора.",
      imageSrc: "assets/500-status-logo.png",
    },
  };
}
