import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  RedistributionsTableService,
  RequestFilterKeys,
} from "./redistributions-table.service";
import { tableHeader } from "@app/shared/redistributions-table.constants";
import { ReddistributionFilterKeys } from "@app/filter/filter.service";
import { CommonModule } from "@angular/common";
import { MedIconResetModule } from "@mk/med-icons";
import { MedPaginationModule } from "@med-ui/med-pagination/med-pagination.module";
import { FilterModule } from "@app/filter/filter.module";
import { TooltipModule } from "@app/tooltip/tooltip.module";
import { ErrorModule } from "@app/error/error.module";
import { ModalRedistributionComponent } from "../modal/modal-redistribution/modal-redistribution.component";
import { TextEllipsisPipe } from "@app/pipes/text-ellipsis.pipe";
import { DateStringPipe } from "@app/pipes/date-string.pipe";
import { ContextMenuComponent } from "@app/context-menu/context-menu.component";
import { DataService } from "@app/shared/data.service";

@Component({
  selector: "app-redistributions-table",
  templateUrl: "./redistributions-table.component.html",
  styleUrls: ["./redistributions-table.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MedIconResetModule,
    MedPaginationModule,
    FilterModule,
    TooltipModule,
    ErrorModule,
    ModalRedistributionComponent,
    TextEllipsisPipe,
    DateStringPipe,
    ContextMenuComponent,
  ],
})
export class RedistributionsTableComponent implements OnInit, AfterViewInit {
  @ViewChild("modalRedistribution")
  modalRedistribution!: ModalRedistributionComponent;
  @ViewChild("contextMenu") contextMenu!: ContextMenuComponent;
  tableHeader: { label: string; key: string }[] = tableHeader;
  tableErrorCode: number = 0;
  tableBody: { [key: string]: any }[] = [];
  tableBodyErrorCode: number = 0;
  pagination = {
    page: 1,
    perPage: 20,
    totalElements: 0,
    totalPages: 0,
  };
  sort: { [key: string]: "ASC" | "DESC" } = {};
  defaultFilters: { [key: string]: any } = {};
  selectedFilters: { [key: string]: any } = {};
  filterKeys: any = ReddistributionFilterKeys;
  requestFilterKeys: any = RequestFilterKeys;
  resetFilters: boolean = false;
  filterCount: number = 0;
  activeRow: any;
  isModalRedistributionVisible: boolean = false;
  userOrganizations: Array<string> = [];
  showMyOrganization: boolean = false;

  constructor(
    private redistributionsTableService: RedistributionsTableService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.getBodyData({ filter: this.defaultFilters });

    this.dataService.showMyOrganizationValue.subscribe((state) => {
      if (state === this.showMyOrganization) return;
      this.showMyOrganization = state;
      const organizationCode = state ? this.userOrganizations : null;
      if (organizationCode) {
        this.defaultFilters = {
          ...this.defaultFilters,
          organizationCode,
        };
      } else {
        delete this.defaultFilters["organizationCode"];
      }
      this.getBodyData({
        filter: {
          ...this.defaultFilters,
          ...this.selectedFilters,
        },
      });
    });

    this.dataService.userOrganizationsValue.subscribe((organizations) => {
      this.userOrganizations = organizations;
    });
  }

  ngAfterViewInit() {
    this.contextMenu.hidden.subscribe(() => {
      if (this.activeRow && !this.isModalRedistributionVisible) {
        this.activeRow = null;
      }
    });
    this.modalRedistribution.visible.subscribe(() => {
      this.isModalRedistributionVisible = true;
    });
    this.modalRedistribution.hidden.subscribe(() => {
      if (this.activeRow) {
        this.isModalRedistributionVisible = false;
        this.activeRow = null;
      }
    });
  }

  getBodyData(payload?: any): void {
    this.redistributionsTableService.getBodyData(payload).subscribe(
      (bodyData) => {
        this.tableBody = bodyData.content.map((row: any) => {
          return {
            ...row.medicineMedicalDevice,
            redistributionOffer: row.offeredAmount || 0,
            bookedQuantity: row.bookedAmount || 0,
          };
        });
        this.pagination.totalPages = bodyData.totalPages;
        this.pagination.totalElements = bodyData.totalElements;
        if (this.tableBody.length === 0) this.tableBodyErrorCode = 404;
        else this.tableBodyErrorCode = 0;
      },
      (error) => {
        if (error.status === 500) this.tableErrorCode = error.status;
        else this.tableBodyErrorCode = error.status;
      }
    );
  }

  getCellTitle(value: string): string {
    return value && value.length > 76 ? value : "";
  }

  isColumnFilterable(columnKey: string): boolean {
    return Object.keys(this.filterKeys).includes(columnKey);
  }

  onFilterChange({ key, payload }: any): void {
    if (!payload || !payload.length) {
      delete this.selectedFilters[this.requestFilterKeys[key]];
      this.filterCount = Object.keys(this.selectedFilters).length;
      this.getBodyData({
        pageable: {
          page: 0,
          size: this.pagination.perPage,
        },
        filter: { ...this.defaultFilters, ...this.selectedFilters },
      });
      return;
    }
    this.selectedFilters[this.requestFilterKeys[key]] = payload;
    this.filterCount = Object.keys(this.selectedFilters).length;
    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: { ...this.defaultFilters, ...this.selectedFilters },
    });
  }

  onResetFilters(): void {
    this.resetFilters = true;
    setTimeout(() => {
      this.resetFilters = false;
    }, 0);
    this.selectedFilters = {};
    this.getBodyData({
      pageable: {
        page: 0,
        size: this.pagination.perPage,
      },
      filter: this.defaultFilters,
    });
  }

  onFilterError(error: any): void {
    console.error(error.text, error.payload);
    this.tableErrorCode = error.payload.status;
  }

  onPageChange(newPage: number): void {
    this.getBodyData({
      filter: { ...this.defaultFilters, ...this.selectedFilters },
      pageable: {
        page: newPage - 1,
        size: this.pagination.perPage,
      },
    });
  }

  onRowClick(event: MouseEvent, row: any): void {
    this.activeRow = row;
    this.contextMenu.show({
      coordinates: { x: event.clientX, y: event.clientY },
      options: [
        {
          label: row.availableQuantity
            ? "Забронювати"
            : "Забронювати (Все заброньовано)",
          action: () => this.modalRedistribution.open(row),
          isAvailable: !!row.availableQuantity,
        },
        {
          label: "Переглянути пропозицію",
          action: () => {},
          isAvailable: false,
        },
      ],
    });
  }

  onTableScroll(): void {
    if (this.contextMenu) {
      this.contextMenu.hide();
    }
  }
}
