import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { Modal } from "bootstrap";

@Component({
  standalone: true,
  selector: "app-modal-container",
  templateUrl: "./modal-container.component.html",
  styleUrls: ["./modal-container.component.scss"],
})
export class ModalContainerComponent implements AfterViewInit {
  @Input() width?: number;
  @Input() height?: number;
  @ViewChild("modal", { static: true }) modalRef!: ElementRef;
  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();
  private modal!: Modal;
  private isVisible = false;

  ngAfterViewInit() {
    this.modal = new Modal(this.modalRef.nativeElement, {});
  }

  close() {
    this.isVisible && this.onClose.emit();
    this.modal.hide();
    this.isVisible = false;
  }

  openModal() {
    this.modal?.show();
    this.isVisible = true;
  }
}
