import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateLocal',
  pure: true,
  standalone: true,
})
export class DateLocalPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '-';
    }

    const [day, month, year, hour, minute] = value.match(/\d+/g)!.map(Number);
    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute));


    return utcDate.toISOString();

  }
}
