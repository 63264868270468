import { Injectable } from "@angular/core";
import { DataService } from "@app/shared/data.service";
import { jwtDecode } from "jwt-decode";
import { env } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export enum UserRole {
  MOZ = "moz",
  DOZ = "doz",
  ZOZ = "zoz",
  MZU = "mzu",
}

@Injectable({
  providedIn: "root",
})
export class UserRolesService {
  organizationCode: string = "";
  userRole = "";
  isZOZUser = false;

  constructor(private http: HttpClient, private dataService: DataService) {
    this.organizationCode = (
      jwtDecode(localStorage["access_token"]) as any
    ).organizationInfo.code;
    const userRoles = (
      jwtDecode(localStorage["access_token"]) as any
    ).realm_access.roles
      .join(" ")
      .toLowerCase();
    this.setUserRole(userRoles);
    this.dataService.organizationCode = this.organizationCode;
    this.getOrganizations();
    // TODO: Revert after ZOZ feature approved
    // this.checkUserRoleAndRedirect();
    // this.checkIfUserIsZOZ();
  }

  private getOrganizations() {
    this.http.get<any>(env.apiUrl + "/accounting/v1/organization").subscribe(
      (data) => {
        this.getUserOrganizations(data);
        this.dataService.allOrganizations = {
          data,
        };
      },
      (error) => {
        this.dataService.allOrganizations = {
          errorCode: error.status,
        };
        console.error("Error organizations data", error);
      }
    );
  }

  private getOrganizationData = (organization: any) => {
    const organizations = Array<string>();
    if (organization.code === this.organizationCode) {
      organizations.push(organization.code.toString());
      organizations.push(organization.id.toString());
      if (organization.children) {
        organization.children.forEach((child: any) => {
          organizations.push(child.code.toString());
          organizations.push(child.id.toString());
        });
      }
    }
    return organizations;
  };

  private getUserOrganizations(data: any) {
    const organizations = Array<string>();
    data.forEach((organization: any) => {
      organizations.push(...this.getOrganizationData(organization));
      if (organization.type.toLowerCase() === UserRole.MOZ) {
        const subordinatedOrgs = data.filter(
          (subordinatedOrg: any) =>
            subordinatedOrg.subOrdinatedToId === organization.id
        );
        subordinatedOrgs.forEach((subordinatedOrg: any) => {
          organizations.push(...this.getOrganizationData(subordinatedOrg));
        });
      }
    });
    this.dataService.userOrganizations = organizations;
  }

  private setUserRole(userRoles: string) {
    this.userRole = UserRole.MZU;
    if (userRoles.includes(UserRole.MOZ)) {
      this.userRole = UserRole.MOZ;
    }
    if (userRoles.includes(UserRole.DOZ)) {
      this.userRole = UserRole.DOZ;
    }
    if (userRoles.includes(UserRole.ZOZ)) {
      this.userRole = UserRole.ZOZ;
    }
    this.dataService.userRole = this.userRole;
  }

  private checkUserRoleAndRedirect() {
    if (this.userRole === UserRole.ZOZ) {
      window.location.href = env.zozRedirectUrl;
    }
  }

  private checkIfUserIsZOZ() {
    if (this.userRole === UserRole.ZOZ) this.isZOZUser = true;
  }
}
