import { Component, Input } from "@angular/core";
import { Router, UrlSegment, UrlTree } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  @Input() items: { link: string; title: string }[] = [];

  constructor(public router: Router) {}

  isActive(link: string): boolean {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    const urlSegments: UrlSegment[] =
      urlTree.root.children["primary"]?.segments || [];
    if (urlSegments.length === 0 && link === "") return true;
    return urlSegments.some((segment) => segment.path === link);
  }
}
